import { ConfigProvider, Form, Input, theme } from 'antd';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from './index.module.css';

export default function InputComponent({ data, item, layout, autoFill }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeFontColor, themeDisabledContainer, autoFillDisabled, themeDangerColor }, } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    activeBorderColor: ap_userPrimaryColor,
                    hoverBorderColor: ap_userPrimaryColor,
                    colorBgContainer: data?.error ? `${themeDangerColor}40` : themePrimaryColor,
                    colorBgContainerDisabled: autoFill ? autoFillDisabled : themeDisabledContainer,
                    colorText: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                    colorBorder: ap_userHighlightColor,
                }
            }}
        >
            {data?.hidden ? null : (
                <div className={(layout === 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout === 'vertical' ?
                            <>
                                <Input
                                    onChange={data?.eventHandler}
                                    value={data?.value}
                                    type={data?.inputType}
                                    status={!!data?.error ? 'error' : ''}
                                    className={styles["InputBox"]}
                                    addonBefore={data?.addonBefore}
                                    addonAfter={data?.addonAfter}
                                    pattern={data?.pattern}
                                    disabled={data?.disabled}
                                    suffix={data?.suffix}
                                    placeholder={data?.placeholder}
                                    size={data?.size}
                                    onFocus={data?.onFocus}
                                    onBlur={data?.onBlur}
                                />
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </>
                            : null}
                    </Form.Item>
                    {layout === 'horizontal' ?
                        <>
                            <Input
                                onChange={data?.eventHandler}
                                value={data?.value}
                                type={data?.inputType}
                                status={!!data?.error ? 'error' : ''}
                                className={styles["InputBox"]}
                                addonBefore={data?.addonBefore}
                                addonAfter={data?.addonAfter}
                                disabled={data?.disabled}
                                suffix={data?.suffix}
                                placeholder={data?.placeholder}
                                size={data?.size}
                                onFocus={data?.onFocus}
                                onBlur={data?.onBlur}
                            />
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </>
                        : null}
                </div>
            )}
        </ConfigProvider>
    )
}