import { Alert, Button, Input } from "antd";
import DocumentList from "../../components/DocumentsList";
import UploadDragger from "../../components/UploadDragger";
import UploadedFileList from "../../components/UploadedFileList";

import styles from './index.module.css';

export default function DocumentRequirements({
    documentContainerHeader,
    documentContainerSubHeader,
    documentsList,
    showDocumentsError,
    folderName,
    files,
    setFiles,
    showFiles = true,
    fileComments,
    setFileComments,
    showActionButton = false,
    actionButtonText = "Submit",
    actionButtonOnClick,
    shouldShowBoxShadow = true,
    setUnsaved,
    opportunityType
}) {

    return (
        <div className={styles["Documents_Container"]}>

            <div className={(shouldShowBoxShadow ? styles["Container"] : styles["ContainerDocumentRequest"])}>

                <div className={`${styles["Docs_Header"]}`}>{documentContainerHeader || "Documents"}</div>
                <div className={`${styles["Docs_SubHeader"]}`}>{documentContainerSubHeader || "The following documents are required."}</div>
                <div className={styles["Docs_List"]}>
                    <DocumentList
                        documentsList={documentsList}
                        opportunityType={opportunityType}
                    />
                </div>
            </div>

            <div className={(shouldShowBoxShadow ? styles["Container"] : styles["ContainerDocumentRequest"])}>
                <div className={`${styles["Header"]}`}>Upload Documents</div>
                {showDocumentsError &&
                    <div style={{ textAlign: 'left', paddingBottom: '10px' }}>
                        <Alert
                            message="Please upload the required documents."
                            type="error"
                            showIcon
                        />
                    </div>
                }
                <div className={(files?.length === 0) ? styles["InnerContainer"] : (shouldShowBoxShadow ? styles["FullInnerContainer"] : styles["RequestDocs_FullInnerContainer"])}>
                    <UploadDragger
                        allowMultiple={true}
                        showUploadList={false}
                        folderName={folderName}
                        setFiles={setFiles}
                    />
                    <div className={(files?.length === 0) ? styles["HideContainer"] : styles["DocsContainer"]}>
                        <UploadedFileList
                            fileList={showFiles ? files : []}
                            setFileList={setFiles}
                        />
                    </div>
                    <div style={{ margin: '20px 0px 0px' }}>
                        <Input.TextArea
                            placeholder="Comments"
                            autoSize={{ minRows: shouldShowBoxShadow ? 5 : 2, maxRows: shouldShowBoxShadow ? 5 : 2 }}
                            value={fileComments}
                            onChange={(e) => {
                                setFileComments(e.target.value);
                                if (setUnsaved) setUnsaved(true);
                            }}
                        />
                    </div>
                </div>
                <div className={showActionButton ? null : styles["HideContainer"]}>
                    <Button
                        type="primary"
                        disabled={files?.length === 0}
                        onClick={actionButtonOnClick}
                    >{actionButtonText}</Button>
                </div>
            </div>
        </div >
    );
}