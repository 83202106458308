import { ConfigProvider, Form, Select, theme } from 'antd';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from "./index.module.css";

export default function DropdownSearch({ data, item, layout }) {
    const { token: { ap_userHighlightColor, themePrimaryColor, themeDangerColor, themeSecondaryColor, themeFontColor, themeDisabledContainer }, } = theme.useToken();
    return (
        <ConfigProvider
            theme={{
                token: {
                    // colorBgContainer: themePrimaryColor,
                    colorBgContainer: data?.error ? `${themeDangerColor}40` : themePrimaryColor,
                    colorBgContainerDisabled: themeDisabledContainer,
                    colorText: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                    controlOutline: ap_userHighlightColor,
                    colorPrimaryHover: ap_userHighlightColor,
                    controlItemBgHover: themeSecondaryColor,
                    colorIconHover: themeSecondaryColor,
                    colorTextQuaternary: themeFontColor,
                    colorBorder: ap_userHighlightColor,
                }
            }}
        >
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout == 'vertical' ?
                            <>
                                <Select
                                    showSearch
                                    value={data.value}
                                    style={{ textAlign: 'left' }}
                                    onChange={data.eventHandler}
                                    placeholder={"Search " + data.label}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={data.dropdownContent}
                                    disabled={data.disabled}
                                />
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </> : null
                        }
                    </Form.Item>
                    {layout == 'horizontal' ?
                        <>
                            <Select
                                showSearch
                                value={data.value}
                                style={{ textAlign: 'left' }}
                                onChange={data.eventHandler}
                                placeholder={"Search " + data.label}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={data.dropdownContent}
                                disabled={data.disabled}
                            />
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </> : null
                    }
                </div>
            )}
        </ConfigProvider>
    );
}