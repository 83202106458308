import constants from "../../constants";
import validate from "../../utils/Validations";
import utils from "../../utils";

import CreatAssetUtils from "./utils";

function AssetFormFields(data = {}, setData, fieldManager = {}, setFieldManager) {
    const invokeFieldValidaton = fieldManager?.invokeFieldValidaton || false;
    const isCreOpp = fieldManager?.isCreOpp || false;
    const isBlOpp = fieldManager?.isBlOpp || false;

    const truckAssetTypes = ['Dump Truck', 'Trucks'];
    const trailerAssetTypes = ['Trailers', 'Reefers'];
    const brandAssetTypes = ['Construction Equipment', 'Other'];
    const BlAssetTypes = ['Amount (PAL)', 'Amount (Working Capital)'];
    const propertyAssetTypes = ['Property'];
    const collateralAssetTypes = ['Collateral'];
    const otherType = ['Other'];
    const specialClass = ['Special Use'];

    const {
        ap_type,
        ap_assetClass,
        ap_specialClass,
        ap_truckBrand,
        ap_trailerBrand,
        ap_brand,
        ap_purpose,
        ap_purposeOther,
        ap_model,
        ap_year,
        ap_vin,
        ap_serialNum,
        ap_kms,
        ap_propertyAddress,
        ap_appraisedAmount,
        ap_existingPayout,
        ap_price,
        ap_platingprovince,
        ap_platingaddress,
        ap_yardaddress,
        ap_vendorId,
        ap_zoning,
        ap_loanType,
    } = data;

    return {
        ap_type: {
            value: ap_type || '',
            error: invokeFieldValidaton ? validate.ValidateRequiredField(ap_type).errorMessage : '',
            disabled: isCreOpp || isBlOpp,
            hidden: false,
            required: true,
            inputType: 'dropdown',
            label: 'Asset Type',
            dropdownContent: CreatAssetUtils.assetTypeDropdownContent(isCreOpp, isBlOpp),
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_type', ['ap_truckBrand', 'ap_trailerBrand', 'ap_brand']),
        },
        // Real Estate Specific
        ap_assetClass: {
            value: ap_assetClass || '',
            error: invokeFieldValidaton && propertyAssetTypes.includes(ap_type) ? validate.ValidateRequiredField(ap_assetClass).errorMessage : '',
            hidden: !propertyAssetTypes.includes(ap_type),
            required: propertyAssetTypes.includes(ap_type),
            inputType: 'dropdown',
            label: 'Asset Class',
            dropdownContent: constants.ASSET_CLASS,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_assetClass'),
        },
        ap_specialClass: {
            value: ap_specialClass || '',
            error: invokeFieldValidaton && propertyAssetTypes.includes(ap_type) && specialClass.includes(ap_assetClass) ? validate.ValidateRequiredField(ap_specialClass).errorMessage : '',
            hidden: !specialClass.includes(ap_assetClass),
            required: specialClass.includes(ap_assetClass),
            inputType: 'text',
            label: 'Special Use Description',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_specialClass'),
        },
        ap_propertyAddress: {
            value: ((propertyAssetTypes.includes(ap_type) || collateralAssetTypes.includes(ap_type)) && ap_propertyAddress) ? ap_propertyAddress : '',
            error: invokeFieldValidaton && (propertyAssetTypes.includes(ap_type) || collateralAssetTypes.includes(ap_type)) ? validate.ValidateRequiredField(ap_propertyAddress).errorMessage : '',
            hidden: !propertyAssetTypes.includes(ap_type) && !collateralAssetTypes.includes(ap_type),
            required: propertyAssetTypes.includes(ap_type) || collateralAssetTypes.includes(ap_type),
            inputType: 'text',
            label: 'Property Address',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_propertyAddress'),
        },

        // Business Loan Specific
        ap_loanType: {
            value: ap_loanType || '',
            error: '',
            hidden: !isBlOpp,
            required: false,
            inputType: 'dropdown',
            label: 'Loan Type',
            dropdownContent: constants.LOAN_TYPES,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_loanType'),
        },
        // Equipment Specific
        ap_truckBrand: {
            value: (truckAssetTypes.includes(ap_type) && ap_truckBrand) ? ap_truckBrand : '',
            error: '',
            hidden: !truckAssetTypes.includes(ap_type),
            required: false,
            inputType: 'dropdown',
            label: 'Truck Brand',
            dropdownContent: constants.TRUCK_BRANDS,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_truckBrand'),
        },
        ap_trailerBrand: {
            value: (trailerAssetTypes.includes(ap_type) && ap_trailerBrand) ? ap_trailerBrand : '',
            error: '',
            hidden: !trailerAssetTypes.includes(ap_type),
            required: false,
            inputType: 'dropdown',
            label: 'Trailer Brand',
            dropdownContent: constants.TRAILER_BRANDS,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_trailerBrand'),
        },
        ap_brand: {
            value: ap_brand || '',
            error: (invokeFieldValidaton && ((ap_truckBrand && otherType.includes(ap_truckBrand)) || (ap_trailerBrand && otherType.includes(ap_trailerBrand))) && !ap_brand),
            hidden: !(otherType.includes(ap_truckBrand) || otherType.includes(ap_trailerBrand) || brandAssetTypes.includes(ap_type)),
            required: (ap_truckBrand && otherType.includes(ap_truckBrand)) || (ap_trailerBrand && otherType.includes(ap_trailerBrand)) || brandAssetTypes.includes(ap_type),
            inputType: 'text',
            label: 'Brand',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_brand'),
        },
        ap_purpose: {
            value: ap_purpose || 'N/A',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'dropdown',
            label: 'Purpose',
            dropdownContent: constants.ASSET_PURPOSES,
            eventHandler: (value) => CreatAssetUtils.handleInput((value || 'N/A'), setData, 'ap_purpose', ['ap_purposeOther']),
        },
        ap_purposeOther: {
            value: (otherType.includes(ap_purpose) && ap_purposeOther) ? ap_purposeOther : '',
            error: invokeFieldValidaton && otherType.includes(ap_purpose) ? validate.ValidateRequiredField(ap_purposeOther).errorMessage : '',
            hidden: !otherType.includes(ap_purpose),
            required: otherType.includes(ap_purpose),
            inputType: 'text',
            label: 'Purpose (Other)',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_purposeOther'),
        },
        ap_model: {
            value: ap_model || '',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Model',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_model'),
        },
        ap_year: {
            value: utils.ValidateYear(String(ap_year || '')).value,
            error: (invokeFieldValidaton && ap_year) ? utils.ValidateYear(String(ap_year || '')).error : '',
            hidden: isCreOpp || isBlOpp || otherType.includes(ap_type) || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Year',
            pattern: /^\d{4}$/,
            eventHandler: (e) => CreatAssetUtils.handleInput(utils.ValidateYear(String(e.target.value || '')).value, setData, 'ap_year'),
        },
        ap_vin: {
            value: validate.ValidateVIN(ap_vin || '').value,
            error: ap_vin ? validate.ValidateVIN(ap_vin || '').error : '',
            hidden: isCreOpp || isBlOpp || otherType.includes(ap_type) || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'VIN',
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateVIN(e.target.value).value, setData, 'ap_vin'),
        },
        ap_serialNum: {
            value: validate.ValidateSerialNumber(ap_serialNum || '').value,
            error: ap_serialNum ? validate.ValidateSerialNumber(ap_serialNum || '').error : '',
            hidden: isCreOpp || isBlOpp || !brandAssetTypes.includes(ap_type) || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Serial #',
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateSerialNumber(e.target.value).value, setData, 'ap_serialNum'),
        },
        ap_kms: {
            value: validate.ValidateKMS(ap_kms || '').value,
            error: ap_kms ? validate.ValidateKMS(ap_kms || '').error : '',
            hidden: isCreOpp || isBlOpp || otherType.includes(ap_type) || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'KMs',
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateKMS(e.target.value).value, setData, 'ap_kms'),
        },

        // Common
        ap_price: {
            value: validate.ValidateCurrency(String(ap_price || '')).value,
            error: invokeFieldValidaton ? validate.ValidateCurrency(String(ap_price || '')).error : '',
            hidden: false,
            required: true,
            inputType: 'text',
            label: 'Amount',
            addonBefore: "$",
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateCurrency(String(e.target.value)).value, setData, 'ap_price'),
        },

        // Real Estate Specific
        ap_appraisedAmount: {
            value: validate.ValidateCurrency(String(ap_appraisedAmount || '')).value,
            error: '',
            hidden: !propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Appraised Amount',
            addonBefore: "$",
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateCurrency(String(e.target.value)).value, setData, 'ap_appraisedAmount'),
        },
        ap_existingPayout: {
            value: validate.ValidateCurrency(String(ap_existingPayout || '')).value,
            error: '',
            hidden: !propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Existing Payout',
            addonBefore: "$",
            eventHandler: (e) => CreatAssetUtils.handleInput(validate.ValidateCurrency(String(e.target.value)).value, setData, 'ap_existingPayout'),
        },
        ap_zoning: {
            value: ap_zoning || '',
            error: '',
            hidden: !propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Zoning',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_zoning'),
        },

        // Equipment Specific
        ap_vendorId: {
            value: ap_vendorId || '',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type),
            required: false,
            inputType: 'add-dropdown-search',
            label: 'Vendor',
            dropdownContent: fieldManager.vendors,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_vendorId'),
            addNew: (value) => CreatAssetUtils.handleNewVendor(value, setFieldManager, setData),
        },
        ap_platingprovince: {
            value: ap_platingprovince || '',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type) || otherType.includes(ap_type),
            required: false,
            inputType: 'dropdown',
            label: 'Plating Province/States',
            dropdownContent: constants.CANADIAN_PROVINCES,
            eventHandler: (value) => CreatAssetUtils.handleInput(value, setData, 'ap_platingprovince'),
        },
        ap_platingaddress: {
            value: ap_platingaddress || '',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type) || otherType.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Plating Address',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_platingaddress'),
        },
        ap_yardaddress: {
            value: ap_yardaddress || '',
            error: '',
            hidden: isCreOpp || isBlOpp || propertyAssetTypes.includes(ap_type) || otherType.includes(ap_type),
            required: false,
            inputType: 'text',
            label: 'Yard Address',
            eventHandler: (e) => CreatAssetUtils.handleInput(e.target.value, setData, 'ap_yardaddress'),
        }
    }
}

const CreatAssetConstants = {
    AssetFormFields,
}

export default CreatAssetConstants;