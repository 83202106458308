import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button, Drawer, Spin, notification, theme } from "antd";
import { PlusOutlined } from '@ant-design/icons';

import ApiService from "../../api";
import validate from "../../utils/Validations";
import HasError from "../../utils/HasErrors";
import InputLayout from "../InputLayout";
import assetConstants from "./constants"

import styles from "./index.module.css";
import utils from "../../utils";

export default function CreateMultipleAssets({ newApp, disabled, assets, setAssets, refreshAssets, setRefreshAssets, secondaryButton, setUnsaved }) {

    const { token: { userPrimaryColor }, } = theme.useToken();

    // Tracks the state of 'Create Multiple Assets' button
    const [invokeFieldValidaton, setInvokeFieldValidation] = useState(false);

    // Default values for the form fields
    const [formData, setFormData] = useState(assetConstants.generateMultipleAssetsFields());

    // Track state for trun Loading Screen on/off
    const [loading, setLoading] = useState(false);

    const [refreshVendor, setRefreshVendor] = useState(0);

    // Tracks state for opening/closing Create Asset Form
    const [open, setOpen] = useState(false);

    // Get the ID of the Application (if exists)
    const { id } = useParams();
    const location = useLocation();
    const isDraft = location?.pathname?.includes("draft");

    // Handle Update Notifications
    const [notifApi, contextHolder] = notification.useNotification();
    const openNotification = (type, message, description) => {
        notifApi[type]({
            message: message,
            description: description,
            placement: 'bottomRight',
        });
    };

    // Event Handlers
    const handleQuantity = (e) => {
        setFormData({
            ...formData,
            ap_quantity: {
                ...formData.ap_quantity,
                value: e.target.value,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            },
        });
    }

    const handleAssetType = (assetType) => {
        setFormData({
            ...formData,
            ap_assetType: {
                ...formData.ap_assetType,
                value: assetType,
                error: invokeFieldValidaton ? validate.ValidateRequiredField(assetType).errorMessage : '',
            },
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                value: '',
                hidden: (assetType !== 'Dump Truck' && assetType !== 'Trucks'),
                required: false,
            },
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                value: '',
                hidden: (assetType !== 'Reefers' && assetType !== 'Trailers'),
                required: false,
            },
            ap_brand: {
                ...formData.ap_brand,
                value: '',
                hidden: (assetType !== 'Construction Equipment' && assetType !== 'Other'),
                required: false,
            },
            ap_year: {
                ...formData.ap_year,
                hidden: (assetType === "Other")
            },
            ap_kms: {
                ...formData.ap_kms,
                hidden: (assetType === "Other")
            },
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                hidden: (assetType === "Other")
            },
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                hidden: (assetType === "Other")
            },
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                hidden: (assetType === "Other")
            }
        });
    }

    const handleBrand = (e) => {
        setFormData({
            ...formData,
            ap_brand: {
                ...formData.ap_brand,
                value: e.target.value,
            },
        });
    }

    const handleTruckBrand = (truckBrand) => {
        setFormData({
            ...formData,
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                value: truckBrand,
            },
            ap_brand: {
                ...formData.ap_brand,
                error: '',
                hidden: truckBrand !== 'Other',
                required: truckBrand === 'Other',
            }
        });
    }

    const handleTrailerBrand = (trailerBrand) => {
        setFormData({
            ...formData,
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                value: trailerBrand,
            },
            ap_brand: {
                ...formData.ap_brand,
                error: '',
                hidden: trailerBrand !== 'Other',
                required: trailerBrand === 'Other',
            }
        });
    }

    const handlePurpose = (purpose) => {
        setFormData({
            ...formData,
            ap_purpose: {
                ...formData.ap_purpose,
                value: purpose,
                error: '',
            },
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                value: purpose !== 'Other' ? '' : formData.ap_purposeOther.value,
                hidden: purpose !== 'Other',
                required: purpose === 'Other',
            }
        });
    }

    const handlePurposeOther = (e) => {
        setFormData({
            ...formData,
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                value: e.target.value,
                error: formData.ap_purposeOther.required ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        });
    }

    // Model Event Hanlder
    const handleModel = (e) => {
        setFormData({
            ...formData,
            ap_model: {
                ...formData.ap_model,
                value: e.target.value,
                error: (invokeFieldValidaton && formData.ap_model.required) ? validate.ValidateRequiredField(e.target.value).errorMessage : '',
            }
        })
    }

    // Year Event Handler
    const handleYear = (e) => {
        setFormData({
            ...formData,
            ap_year: {
                ...formData.ap_year,
                value: utils.ValidateYear(String(e.target.value)).value,
                error: invokeFieldValidaton ? utils.ValidateYear(String(e.target.value)).error : '',
            }
        })
    }

    // KMs Event Handler
    const handleKms = (e) => {
        setFormData({
            ...formData,
            ap_kms: {
                ...formData.ap_kms,
                value: validate.ValidateKMS(e.target.value).value,
                error: e.target.value ? validate.ValidateKMS(e.target.value).error : '',
            }
        })
    }

    const handlePrice = (e) => {
        setFormData({
            ...formData,
            ap_totalPrice: {
                ...formData.ap_totalPrice,
                value: validate.ValidateCurrency(String(e.target.value)).value,
                error: invokeFieldValidaton ? validate.ValidateCurrency(String(e.target.value)).error : '',
            }
        });
    }

    // Vendor Event Hanlder
    const handleVendor = (vendor) => {
        setFormData({
            ...formData,
            ap_vendorId: {
                ...formData.ap_vendorId,
                value: vendor,
            }
        });
    }

    // Province or State Event Handlers
    const handleProvinceStates = (province) => {
        setFormData({
            ...formData,
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                value: province,
            }
        });
    }

    // Plating Address Event Handler
    const handlePlatingAddress = (e) => {
        setFormData({
            ...formData,
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                value: e.target.value,
            }
        });
    }

    // Yard Address Event Handler
    const handleYardAddress = (e) => {
        setFormData({
            ...formData,
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                value: e.target.value,
            }
        });
    }

    // Handle Adding a new Vendor
    const handleNewVendor = (vendorName) => {
        if (vendorName) {
            setLoading(true);
            ApiService.createVendor({ ap_name: vendorName }).then((resp) => {
                if (resp?.error) {
                    openNotification('error', 'Error', resp.error);
                    return;
                }

                const tempArr = [{ label: resp?.data?.ap_name, value: resp?.data?._id }]
                setFormData({
                    ...formData,
                    ap_vendorId: {
                        ...formData.ap_vendorId,
                        dropdownContent: [...formData.ap_vendorId.dropdownContent, ...tempArr],
                        value: resp?.data?._id,
                    }
                });
                setRefreshVendor(prevRefreshVendor => prevRefreshVendor + 1);
                setTimeout(() => setLoading(false), 2500);
            });
        }
    }

    // Fetch Vendor List
    useEffect(() => {
        setLoading(true);
        ApiService.fetchVendorsList().then((data) => {
            setFormData({
                ...formData,
                ap_vendorId: {
                    ...formData.ap_vendorId,
                    dropdownContent: data,
                }
            });
        });
        setLoading(false);
    }, [open, refreshVendor]);

    const createMultipleAssets = () => {
        setLoading(true);
        setInvokeFieldValidation(true);

        // Validate Form Fields
        const tempData = {
            ...formData,
            ap_quantity: {
                ...formData.ap_quantity,
                error: validate.ValidateRequiredField(formData.ap_quantity.value).errorMessage,
            },
            ap_assetType: {
                ...formData.ap_assetType,
                error: validate.ValidateRequiredField(formData.ap_assetType.value).errorMessage,
            },
            ap_truckBrand: {
                ...formData.ap_truckBrand,
                error: '',
            },
            ap_trailerBrand: {
                ...formData.ap_trailerBrand,
                error: '',
            },
            ap_brand: {
                ...formData.ap_brand,
                error: '',
            },
            ap_purpose: {
                ...formData.ap_purpose,
                error: formData?.ap_purpose?.required ? validate.ValidateRequiredField(formData.ap_purpose.value).errorMessage : "",
            },
            ap_purposeOther: {
                ...formData.ap_purposeOther,
                error: formData.ap_purposeOther.required ? validate.ValidateRequiredField(formData.ap_purposeOther.value).errorMessage : '',
            },
            ap_model: {
                ...formData.ap_model,
                error: '',
            },
            ap_year: {
                ...formData.ap_year,
                error: utils.ValidateYear(String(formData.ap_year.value)).error,
            },
            ap_kms: {
                ...formData.ap_kms,
                error: formData.ap_kms.required ? validate.ValidateKMS(formData.ap_kms.value).error : '',
            },
            ap_totalPrice: {
                ...formData.ap_totalPrice,
                error: validate.ValidateCurrency(String(formData.ap_totalPrice.value)).error,
            },
            ap_vendorId: {
                ...formData.ap_vendorId,
                error: '',
            },
            ap_platingprovince: {
                ...formData.ap_platingprovince,
                error: '',
            },
            ap_platingaddress: {
                ...formData.ap_platingaddress,
                error: '',
            },
            ap_yardaddress: {
                ...formData.ap_yardaddress,
                error: '',
            }
        }

        // Update Form Data
        setFormData(tempData);

        if (!HasError(tempData)) {

            const make = (
                ((formData?.ap_truckBrand?.value && formData?.ap_truckBrand?.value !== "Other") ? formData?.ap_truckBrand?.value : '') ||
                ((formData?.ap_trailerBrand?.value && formData?.ap_trailerBrand?.value !== "Other") ? formData?.ap_trailerBrand?.value : '') ||
                formData?.ap_brand?.value || ''
            );

            const name = [
                formData.ap_year.value,
                make,
                formData.ap_model.value,
                formData.ap_assetType.value
            ];

            const newData = {
                ap_name: name.filter(item => item !== "").join(" "),
                ap_type: tempData.ap_assetType.value,
                ap_brand: tempData.ap_brand.value,
                ap_purpose: formData.ap_purpose.value || 'N/A',
                ap_purposeOther: formData.ap_purposeOther.value,
                ap_make: make,
                ap_model: formData.ap_model.value,
                ap_year: formData.ap_year.value,
                ap_kms: formData.ap_kms.value,
                ap_price: utils.convertToDBCurrency(Number(tempData.ap_totalPrice.value) / tempData.ap_quantity.value),
                ap_platingaddress: formData.ap_platingaddress.value,
                ap_yardaddress: formData.ap_yardaddress.value,
                ap_new: true
            }

            if (formData?.ap_platingprovince?.value)
                newData.ap_platingprovince = formData.ap_platingprovince.value;

            if (formData?.ap_vendorId?.value) {
                newData.ap_vendorId = formData.ap_vendorId.value;
            }

            if (tempData.ap_truckBrand.value) {
                newData.ap_truckBrand = tempData.ap_truckBrand.value;
            }

            if (tempData.ap_trailerBrand.value) {
                newData.ap_trailerBrand = tempData.ap_trailerBrand.value;
            }
            newData.ap_make = tempData.ap_truckBrand.value ? tempData.ap_truckBrand.value : (tempData.ap_trailerBrand.value ? tempData.ap_trailerBrand.value : (tempData.ap_brand.value ? tempData.ap_brand.value : ''))
            newData.ap_vin = '';
            newData.ap_serialNumber = '';
            newData.ap_vinSerial = '';
            const appIdKey = isDraft ? 'ap_applicationDraftId' : 'ap_applicationId';
            newData[appIdKey] = id;

            let tempAssets = [];
            for (let i = 0; i < tempData.ap_quantity.value; i++) {
                tempAssets[i] = newData;
            }
            setAssets([...assets, ...tempAssets]);
            if (setUnsaved) setUnsaved(true);
            openCreateMultipleAssetsForm();
        }

        setInvokeFieldValidation(false);
        setLoading(false);
    }

    const openCreateMultipleAssetsForm = () => {
        setOpen(!open);
        setInvokeFieldValidation(false);
        setFormData(assetConstants.generateMultipleAssetsFields());
    }

    // Generate Form Fields with Event Handlers
    const formFields = {
        ...formData,
        ap_quantity: {
            ...formData.ap_quantity,
            eventHandler: handleQuantity,
        },
        ap_assetType: {
            ...formData.ap_assetType,
            eventHandler: handleAssetType,
        },
        ap_truckBrand: {
            ...formData.ap_truckBrand,
            eventHandler: handleTruckBrand,
        },
        ap_trailerBrand: {
            ...formData.ap_trailerBrand,
            eventHandler: handleTrailerBrand,
        },
        ap_brand: {
            ...formData.ap_brand,
            eventHandler: handleBrand,
        },
        ap_purpose: {
            ...formData.ap_purpose,
            eventHandler: handlePurpose,
        },
        ap_purposeOther: {
            ...formData.ap_purposeOther,
            eventHandler: handlePurposeOther,
        },
        ap_model: {
            ...formData.ap_model,
            eventHandler: handleModel,
        },
        ap_year: {
            ...formData.ap_year,
            eventHandler: handleYear,
        },
        ap_kms: {
            ...formData.ap_kms,
            eventHandler: handleKms,
        },
        ap_totalPrice: {
            ...formData.ap_totalPrice,
            eventHandler: handlePrice,
        },
        ap_vendorId: {
            ...formData.ap_vendorId,
            eventHandler: handleVendor,
            addNew: handleNewVendor,
        },
        ap_platingprovince: {
            ...formData.ap_platingprovince,
            eventHandler: handleProvinceStates,
        },
        ap_platingaddress: {
            ...formData.ap_platingaddress,
            eventHandler: handlePlatingAddress,
        },
        ap_yardaddress: {
            ...formData.ap_yardaddress,
            eventHandler: handleYardAddress,
        },
    }

    return (
        <>
            <Button
                type="primary"
                disabled={disabled}
                className={styles[secondaryButton ? "SecondaryButton" : "CreateButton"]}
                icon={<PlusOutlined />}
                onClick={openCreateMultipleAssetsForm}
            >
                Multiple Asset
            </Button>

            <Drawer
                title="New Asset"
                open={open}
                onClose={openCreateMultipleAssetsForm}
                getContainer=".AppTheme"
            >
                <Spin spinning={loading} tip="Loading..." size="large">

                    <InputLayout
                        data={formFields}
                        layout="vertical"
                        setData={setFormData}
                    />

                    <Button
                        type="primary"
                        style={{ backgroundColor: userPrimaryColor, display: 'block', width: 'fit-content', }}
                        icon={<PlusOutlined />}
                        onClick={createMultipleAssets}
                    >
                        Create Multiple Asset
                    </Button>
                </Spin>

            </Drawer>
            {contextHolder}
        </>
    );
}