import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Checkbox, Input, Modal, Spin, Upload, theme } from "antd";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";

import ApiService from "../../api";
import NotificationService from "../../services/notification.service";

import utils from "../../utils";

import InputLayout from "../InputLayout";
import ApplicationAssetList from "../ApplicationAssetList";
import FileTypeIcons from "../../assets/images/icons/FileTypeIcons";
import CancelApplication from "../CancelApplication";
import PGSnCoApps from "../PGSnCoApps";

import AppConstants from "./constants";
import AppUtils from "./utils";
import styles from "./index.module.css";
import RequiredField from "../../utils/Validations/RequiredField";
import ReopenApplication from "../ReopenApplication";
import CloseApprovalsForApplication from "../CloseApprovalsForApplication";
import dayjs from "dayjs";

export default function Application({ setRefresh, refresh, setHeaderName }) {

    const { token: { themeFontColor } } = theme.useToken();

    const { id } = useParams();
    const navigate = useNavigate();

    // TODO: Reduce the number of states variables by merging some into objects
    // NOTE: Will need to adjust some components to use the merged state vars
    const [loader, setLoader] = useState({ loading: false, message: '' });

    const [applicationData, setApplicationData] = useState({});
    const [appDetailsData, setAppDetailsData] = useState(AppConstants.ApplicationDetails(id, applicationData));
    const [keyMileStonesData, setKeyMileStonesData] = useState(AppConstants.KeyMilestoneDates(id, applicationData));

    const [assets, setAssets] = useState([]);
    const [showAssetError, setShowAssetError] = useState(false);

    const [files, setFiles] = useState([]);
    const [contactList, setContactList] = useState([]);

    const [validatePg, setValidatePg] = useState(0);
    const [pgData, setPgData] = useState([]);
    const [pgErrorExists, setPgErrorExists] = useState(false);

    const [coApps, setCoApps] = useState([]);
    const [coAppErrorExists, setCoAppErrorExists] = useState(false);

    const [refreshAssets, setRefreshAssets] = useState(0);
    const [fileComments, setFileComments] = useState("");

    const [documentDetails, setDocumentDetails] = useState([]);
    const [documentsList, setDocumentsList] = useState(AppConstants.Documents(applicationData?.ap_clientId?._id, assets, pgData, applicationData?.ap_opportunitytype));
    const [showDocumentsError, setShowDocumentsError] = useState(false);

    const [sent, setSent] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);

    const [reason, setReason] = useState(AppConstants.UpdateReason());
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    const [drawdownList, setDrawdownList] = useState([]);
    const [showBtn, setShowBtn] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [reqId, setReqId] = useState();

    const isNewApp = !id;
    const isAppOpen = !id || applicationData?.ap_stateCode === 'Open';
    const isChecked = Object.values(documentsList).some(doc => doc.checked);

    const agentId = JSON.parse(localStorage?.getItem('user'))?.agent_id;

    // Initial Run: Fetch Client List
    useEffect(() => {
        const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;
        setLoader({ loading: true, message: 'Fetching Clients...' });
        ApiService.fetchAgentClient(agentId).then(clientList => {
            setLoader({ loading: false, message: '' });
            if (!clientList) return;
            setAppDetailsData(prevAppDetailsData => ({
                ...prevAppDetailsData,
                client: {
                    ...prevAppDetailsData.client,
                    dropdownContent: clientList
                }
            }));
        });
    }, []);

    useEffect(() => {
        const clientId = applicationData?.ap_clientId?._id || appDetailsData?.client?.value;
        let clientType = appDetailsData?.ap_clientId?.ap_type;

        if (clientId) {
            setLoader({ loading: true, message: 'Fetching Client Contacts...' });
            ApiService.fetchClientContacts(clientId).then(response => {
                setLoader({ loading: false, message: '' });
                setContactList(response.map(({ _id, ap_fullName }) => ({ label: ap_fullName, value: _id })));
            });
        }

        if (!clientType) {
            setLoader({ loading: true, message: 'Fetching Client Details...' });
            ApiService.fetchClientDetails(clientId).then(clientData => {
                setLoader({ loading: false, message: '' });
                if (!clientData) return;
                clientType = clientData?.ap_type;
                setDocumentsList(AppConstants.Documents(clientType, assets, pgData, applicationData?.ap_opportunitytype));
            });
        } else setDocumentsList(AppConstants.Documents(clientType, assets, pgData, applicationData?.ap_opportunitytype));
    }, [applicationData?.ap_clientId?._id, assets, appDetailsData?.client?.value]);

    // Runs when the URL is changed or the refresh state is changed
    useEffect(() => {
        // If there is no ID, then we are creating a new application
        if (!id) return;

        setLoader({ loading: true, message: 'Fetching data...' });

        ApiService.fetchIndividualApplication(id).then((appData) => {
            // If No application data was returned
            if (!appData) {
                setLoader({ loading: false, message: '' });
                return;
            }

            // Clean the currency data and set it to the state
            setApplicationData({
                ...appData,
                ap_requestedAmount: utils.parseDBCurrency(appData?.ap_requestedAmount),
            });

            setAppDetailsData(AppConstants.ApplicationDetails(id, appData));
            setKeyMileStonesData(AppConstants.KeyMilestoneDates(id, appData));

            setFiles(appData?.ap_documents?.files || [])
            // setFileDetails(appData?.ap_documents?.files || [])

            setLoader({ loading: false, message: '' });
        });
    }, [id, refresh]);

    useEffect(() => {
        if (!id) return;

        setLoader({ loading: true, message: 'Fetching Assets...' });
        // Fetch Application Assets
        ApiService.fetchApplicationAssetList(id).then(appAssets => {
            if (!appAssets) return;
            setAssets(appAssets.map(item => ({ ...item, ap_price: utils.parseDBCurrency(item?.ap_price) })));
        });
        setLoader({ loading: false, message: '' });
    }, [id, refreshAssets]);

    useEffect(() => {
        // if (validatePg)
        setOpenSendModal(prevOpenModal => {
            if (pgErrorExists || coAppErrorExists) {
                NotificationService.error('Please fill all the details');
                return false;
            }
            else return prevOpenModal;
        })
    }, [pgErrorExists]);

    // Fetch all drawdowns for application ID
    useEffect(() => {
        if (!id) return;
        setLoader({ loading: true, message: 'Fetching data...' });
        ApiService.fetchAllDrawdownsForApplication(id).then((response) => {
            if (!response) {
                setLoader({ loading: false, message: '' });
                return;
            }
            setDrawdownList(response);
        });
    }, []);
    // filter drawdown on basis of their ap_processStage
    useEffect(() => {
        let flag = true;

        if (drawdownList?.length > 0) {
            for (const item of drawdownList) {
                if (item?.ap_processStage !== "Completed" && item?.ap_processStage !== "Lost") {
                    flag = false;
                    break; // Exit loop as soon as flag is false
                }
            }
        } else {
            flag = false; // Set flag to false if drawdownList is empty
        }

        setShowBtn(flag); // Update showBtn based on the flag value
    }, [drawdownList]);

    // Form Fields with Handlers
    const ApplicationDetailsFields = {
        client: {
            ...appDetailsData?.client,
            eventHandler: (value) => AppUtils.handleClient(value, setLoader, setAppDetailsData),
        },
        applicationType: {
            ...appDetailsData?.applicationType,
            eventHandler: (value) => AppUtils.handleApplicationType(value, setAppDetailsData),
        },
        creApplicationType: {
            ...appDetailsData?.creApplicationType,
            eventHandler: (value) => AppUtils.handleCREApplicationType(value, setAppDetailsData),
        },
        filedFSYearEnd: {
            ...appDetailsData?.filedFSYearEnd,
            eventHandler: (date, dateString) => AppUtils.handleFiledFSYearEnd(dateString, setAppDetailsData),
        }
    }

    const KeyMileStonesFields = {
        closingDate: {
            ...keyMileStonesData?.closingDate,
            eventHandler: (date, dateString) => AppUtils.handleClosingDate(dateString, setKeyMileStonesData),
        },
        waiverDate: {
            ...keyMileStonesData?.waiverDate,
            eventHandler: (date, dateString) => AppUtils.handleWaiverDate(dateString, setKeyMileStonesData),
        }

    }

    const reasonFields = {
        reason: {
            ...reason?.reason,
            eventHandler: (e) => setReason(prevReason => ({
                ...prevReason,
                reason: {
                    ...prevReason?.reason,
                    value: e.target.value,
                    error: RequiredField(e.target.value)
                }
            }))
        }
    }

    useEffect(() => {
        if (files?.length === 0) return;
        setShowDocumentsError(false);

        const filesArr = files.map(({ url }) => url);
        setLoader({ loading: true, message: 'Fetching File Details...' });
        ApiService.fetchArrayFileDetails(filesArr).then(response => {
            setLoader({ loading: false, message: '' });
            if (!response) return;
            setDocumentDetails(response);
        });
    }, [files]);

    // fetch requirements if exists for this application
    useEffect(() => {
        if (!id) return;
        setLoader({ loading: true, message: 'Fetching data...' });

        ApiService.fetchRequirementsList(agentId, id).then((response) => {
            if (!response) {
                setLoader({ loading: false, message: '' });
                return;
            }
            setReqId(response[0]?._id);
        });
    }, []);

    const [refreshPgs, setRefreshPgs] = useState(0);

    return (
        <Spin
            spinning={loader.loading}
            tip={loader.message}
            size="large"
        >
            <div className={(applicationData?.ap_stateCode === 'Lost') ? styles["CanceledAlert"] : styles["HideContainer"]}>
                <Alert message="This application has been cancelled." type="error" showIcon />
            </div>

            {sent && (!loader.loading) ?
                <div className={styles["Applications_ContainerSent"]}>
                    <div>
                        <CheckCircleFilled style={{ color: "#2BD700", fontSize: "90px", display: 'flex', justifyContent: 'center' }} />
                        <div style={{ fontWeight: 'bold', fontSize: "30px", paddingTop: '2%', width: '100%', height: 'fit-content' }}>Application Submitted</div>
                        <Button
                            type="primary"
                            onClick={() => navigate("/applications")}
                            style={{ marginTop: '2%' }}
                        >Back to Applications</Button>
                    </div>
                </div> :
                <>
                    <div className={`${styles['Container']}`}>

                        {reqId ? (
                            <div className={styles["WarningAlert"]}>
                                <Alert type="warning"
                                    showIcon
                                    message={<div className={styles["Banner_Container"]}>
                                        <div>
                                            Requirements exist for this Application
                                        </div>
                                        <div className={styles["RequirementBtn_Container"]}>
                                            <Button
                                                className={styles["RequirementBtn"]}
                                                onClick={() => navigate(`/requirements/${reqId}`)}
                                            >Requirements
                                            </Button>
                                        </div>
                                    </div>} />
                            </div>
                        )
                            :
                            null
                        }
                        <div id="AppDetailsContainer" className={`${styles['Header']}`}>Details</div>

                        <div className={`${styles['Details']}`}>
                            {Object.keys(ApplicationDetailsFields).map((key, index) => {
                                const eachData = { [key]: ApplicationDetailsFields[key] }

                                if (ApplicationDetailsFields[key]?.hidden)
                                    return;

                                return (
                                    <InputLayout
                                        key={index}
                                        data={eachData}
                                        layout="vertical"
                                    />
                                )
                            })}
                        </div>

                    </div>

                    <div className={styles[(applicationData?.ap_opportunitytype === 'Commercial Real Estate') ? 'Container' : 'HideContainer']}>
                        <div id="AppDetailsContainer" className={`${styles['Header']}`}>Key Milestone Dates</div>

                        <div className={`${styles['Details']}`}>
                            {Object.keys(KeyMileStonesFields).map((key, index) => {
                                const eachData = { [key]: KeyMileStonesFields[key] }
                                return (
                                    <InputLayout
                                        key={index}
                                        data={eachData}
                                        layout="vertical"
                                    />
                                )
                            })}
                        </div>
                    </div>

                    <PGSnCoApps
                        applicationId={id}
                        validatePg={validatePg}
                        setPgErrorExists={setPgErrorExists}
                        pgData={pgData}
                        setPgData={setPgData}

                        applicationData={applicationData}
                        coApps={coApps}
                        setCoApps={setCoApps}
                        setCoAppErrorExists={setCoAppErrorExists}

                        clientId={applicationData?.ap_clientId?._id || appDetailsData?.client?.value}
                        contactList={contactList}

                        isAppOpen={isAppOpen}
                        setLoader={setLoader}

                        refreshPgs={refreshPgs}
                        setRefreshPgs={setRefreshPgs}
                    />

                    <ApplicationAssetList
                        id={id}
                        clientId={applicationData?.ap_clientId?._id}
                        assets={assets}
                        setAssets={setAssets}
                        refreshAssets={refreshAssets}
                        setRefreshAssets={setRefreshAssets}
                        showAssetError={showAssetError}
                        setShowAssetError={setShowAssetError}
                        isAppOpen={isAppOpen}
                        opportunityType={applicationData?.ap_opportunityType}
                    />

                    {/* Documents for Existing Application */}
                    <div className={!isNewApp ? styles["Container"] : styles["HideContainer"]}>
                        <div className={styles["ExistingDocs_Header"]}>
                            <div className={styles["Doc_Header_1"]}>Documents</div>
                            <Upload
                                multiple={false}
                                showUploadList={false}
                                customRequest={(file) => AppUtils.handleFileUpload(id, file, setFiles)}
                            >
                                <Button
                                    type="primary"
                                    disabled={!isAppOpen}
                                    className={styles["AddDocumentButton"]}
                                >+ Document</Button>
                            </Upload>
                        </div>

                        <div>
                            {documentDetails?.map((item, index) => (
                                <div key={item.fileUrl} className={styles["listContainer"]}>
                                    <div className={styles["listContainerGrid"]}>
                                        {/* Icon */}
                                        <div className={styles["listIcon"]}>
                                            {FileTypeIcons(item.fileName.split('.')[1], themeFontColor)}
                                        </div>

                                        <div className={styles["listDetails"]}>

                                            <div className={styles['fileStyle']}>
                                                <a
                                                    href={utils.FormBlobFileUrl(item.fileUrl)}
                                                    className={styles["fileName"]}
                                                >{item.fileName}</a>

                                            </div>
                                            <div style={{ textAlign: 'right' }} className="Font_UltraLight">{item?.fileSize}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={!isNewApp ? styles["HideContainer"] : styles["SendAppContainer"]}>
                        <Button
                            type="primary"
                            disabled={loader?.loading}
                            className={styles["SendButton"]}
                            onClick={() => AppUtils.handleSendApplication(setValidatePg, setLoader, appDetailsData, setAppDetailsData, assets, setShowAssetError, files, setShowDocumentsError, pgData, coApps, setOpenSendModal)}
                        >Send Application</Button>
                    </div>

                    <Modal
                        title={null}
                        footer={null}
                        closable={false}
                        destroyOnClose={true}
                        open={openSendModal}
                        onCancel={() => setOpenSendModal(false)}
                        getContainer=".AppTheme"
                        centered
                    >
                        <Spin spinning={loader.loading} tip={loader.message} size="large">
                            <div className={styles["ModalTitle"]}>Before Submitting</div>
                            <div className={styles["ModalSubTitle"]}>Please select which documents you have uploaded.</div>
                            <div className={styles["Checklist"]}>
                                {Object.values(documentsList).map((document, index) => {
                                    if (!document?.hidden)
                                        return (
                                            <div className={styles["IndividualCheckbox"]}>
                                                <Checkbox
                                                    key={index}
                                                    checked={document.checked}
                                                    onChange={() => {
                                                        const updatedDocumentList = { ...documentsList };
                                                        updatedDocumentList[Object.keys(documentsList)[index]].checked = !document.checked;
                                                        setDocumentsList(updatedDocumentList);
                                                    }}
                                                >
                                                    <div className={(document?.checked) ? styles["checked"] : null}>
                                                        <div>{document.name}</div>
                                                        <div className={styles["IndividualCheckbox_AdditionalInfo"]}>{document.additionalInfo}</div>
                                                    </div>
                                                </Checkbox>
                                            </div>
                                        )
                                })}
                            </div>
                            <div className={styles["ModalActions"]}>
                                <Button
                                    type="primary"
                                    className={styles["SubmitButton"]}
                                    disabled={!isChecked}
                                    onClick={() => AppUtils.handleSubmit(id, setLoader, appDetailsData, assets, pgData, coApps, files, fileComments, documentsList, setRefresh, setSent, setHeaderName, setOpenSendModal)}
                                >Submit</Button>
                                <Button
                                    className={styles["CanceButton"]}
                                    onClick={() => setOpenSendModal(false)}
                                >Cancel</Button>
                            </div>
                        </Spin>
                    </Modal>

                    <div className={(!isNewApp && applicationData?.ap_stateCode === 'Open') ? styles["UpdateButton_Container"] : styles["HideContainer"]}>
                        <Button
                            type="primary"
                            className={styles["UpdateButton"]}
                            onClick={() => AppUtils.updateApplication(setLoader, setRefresh, applicationData, id, assets, setRefreshAssets, files, coApps, pgData, setValidatePg, openUpdateModal, setOpenUpdateModal, reason?.reason?.value, setReason, setRefreshPgs, keyMileStonesData)}
                        >Update Application</Button>
                        {(isAppOpen === true && showBtn === true) ? (
                            <Button
                                onClick={() => setShowModal(true)}
                                type="primary"
                                className={styles["CloseButton"]}
                            >
                                Close Application
                            </Button>
                        ) :
                            (
                                <CancelApplication
                                    applicationId={id}
                                    setRefresh={setRefresh}
                                />
                            )}
                    </div>

                    <CloseApprovalsForApplication setRefresh={setRefresh} clientId={applicationData?.ap_clientId?._id} showModal={showModal} setShowMoadal={setShowModal} businessName={`${dayjs(applicationData?.createdAt).format("MMMM YYYY")} - ${applicationData?.ap_clientId?.ap_businessName} - ${applicationData?.ap_clientId?.ap_type}`} applicationID={applicationData?._id} />

                    <div className={(!isNewApp && applicationData?.ap_stateCode === 'Lost') ? styles["ReactivateButton_Container"] : styles["HideContainer"]}>

                        <ReopenApplication
                            applicationId={id}
                            setRefresh={setRefresh}
                        />
                    </div>

                    <Modal
                        title={
                            <div className={styles["TitleFlex"]}>
                                <CloseOutlined
                                    // style={{ visibility: loading ? 'hidden' : 'visible' }}
                                    className={styles["Close"]}
                                    onClick={() => setOpenUpdateModal(false)}
                                />
                                <div className={`${styles["TitleHeader"]} Font_SemiBold`}>Update Application</div>
                            </div>
                        }
                        footer={null}
                        closable={false}
                        destroyOnClose={true}
                        open={openUpdateModal}
                        onCancel={() => setOpenUpdateModal(false)}
                        getContainer=".AppTheme"
                        centered
                    >
                        <Spin spinning={loader.loading} tip={loader.message} size="large">
                            <div className={styles["ModalSubTitle"]}>Please descibe the reason for the updates made to this application.</div>
                            <InputLayout
                                data={reasonFields}
                                layout="vertical"
                                rowCount={3}
                            />
                            <div className={styles["ModalAction"]}>
                                <Button
                                    type="primary"
                                    onClick={() => AppUtils.updateApplication(setLoader, setRefresh, applicationData, id, assets, setRefreshAssets, files, coApps, pgData, setValidatePg, openUpdateModal, setOpenUpdateModal, reason?.reason?.value, setReason)}
                                >Update</Button>
                            </div>
                        </Spin>
                    </Modal>
                </>
            }
        </Spin >
    )
}