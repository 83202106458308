import ApiService from "../../api";
import constants from "../../constants";
import utils from "../../utils";
import HasError from "../../utils/HasErrors";
import CreatAssetConstants from "./constants";

function verticalSetter(opportunityType, setFieldManager) {
    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        isCreOpp: opportunityType === 'Commercial Real Estate',
        isBlOpp: opportunityType === 'Business Lending',
    }));
}

function creTypeAssociator(isCreOpp, isBlOpp, assets, setData) {
    setData(prevData => ({
        ...prevData,
        ap_type: isCreOpp ? (propertyAssetExists(assets) ? 'Collateral' : 'Property') : (isBlOpp ? 'Business Loan' : prevData.ap_type),
    }));
}

function propertyAssetExists(assets) {
    return assets.some(asset => asset.ap_type === 'Property');
}

function assetTypeDropdownContent(isCreOpp, isBlOpp) {
    if (isCreOpp)
        return constants.CRE_ASSET_TYPES;
    else if (isBlOpp)
        return constants.BL_ASSET_TYPES;
    else
        return constants.EQUIPMENT_ASSET_TYPES;
}

function handleInput(value = "", setData, field, resetValues = []) {
    if (field && setData) {
        setData(prevData => {
            const newData = { ...prevData, [field]: value };

            resetValues.forEach(key => {
                if (key in newData) {
                    newData[key] = "";
                }
            });
            return newData;
        });
    }
};

function handleNewVendor(vendorName = '', setFieldManager, setData) {
    if (!vendorName)
        return;

    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        loading: true,
    }));

    ApiService.createVendor({ ap_name: vendorName })
        .then(response => {
            if (response?.error) {
                return;
            }

            const newVendor = [{
                label: response?.data?.ap_name,
                value: response?.data?._id,
            }];

            setFieldManager(prevFieldManager => ({
                ...prevFieldManager,
                vendors: [...prevFieldManager.vendors, ...newVendor],
            }));

            setData(prevData => ({
                ...prevData,
                ap_vendorId: response?.data?._id,
            }));

            // TODO: Check if this Timeout is necessary
            setTimeout(() => {
                setFieldManager(prevFieldManager => ({
                    ...prevFieldManager,
                    loading: false,
                    refreshVendors: prevFieldManager.refreshVendors + 1,
                }));
            }, 2500);
        });
}

function fetchVendorList(setFieldManager) {
    // Start Loading
    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        loading: true,
    }));

    // Fetch Vendor List
    ApiService.fetchVendorsList()
        .then(vendorList => {
            setFieldManager(prevFieldManager => ({
                ...prevFieldManager,
                vendors: vendorList || [],
                loading: false,
            }))
        });

    // End Loading
    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        loading: false,
    }));
}

function handleFormVisibility(setFieldManager, setData) {
    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        open: !prevFieldManager.open,
        invokeFieldValidaton: false,
    }));

    setData({});
}

// Function to scroll to a specific field by name
function scrollToField(fieldRefs, fieldName) {
    if (fieldRefs.current[fieldName]) {
        fieldRefs.current[fieldName].current.scrollIntoView({ behavior: "smooth" });
    }
};

function firstErrorField(obj) {
    for (const key in obj) {
        if (obj[key].error) {
            return key;
        }
    }
    return "";
}

function handleCreateAsset(data = {}, setData, fieldManager = {}, setFieldManager, fieldRefs = {}, newApp = false, id = null, setAssets, setUnsaved) {
    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        loading: true,
        invokeFieldValidaton: true,
    }));

    const updatedFieldManager = { ...fieldManager, invokeFieldValidaton: true };

    const tempData = CreatAssetConstants.AssetFormFields(data, setData, updatedFieldManager, setFieldManager);

    const errorField = firstErrorField(tempData);

    if (!HasError(tempData)) {
        createAsset(data, setFieldManager, tempData, newApp, id, setAssets, setUnsaved);
    } else {
        scrollToField(fieldRefs, errorField);


        setFieldManager(prevFieldManager => ({
            ...prevFieldManager,
            loading: false,
        }));
    }
}

async function createAsset(data, setFieldManager, formData, newApp, id, setAssets, setUnsaved) {

    const { ap_type } = data;

    let assetData = {};
    if (ap_type === 'Property' || ap_type === 'Collateral')
        assetData = await realEstateAssetBuilder(formData);
    else if (ap_type === 'Business Loan')
        assetData = await businessLendingAssetBuilder(formData);
    else
        assetData = await defaultAssetBuilder(formData, newApp, id);

    setAssets(prevAssets => [...prevAssets, assetData]);

    if (setUnsaved)
        setUnsaved(true);

    setFieldManager(prevFieldManager => ({
        ...prevFieldManager,
        open: false,
        loading: false,
        invokeFieldValidaton: false,
    }));
}

async function realEstateAssetBuilder(formData) {
    const name = `${formData.ap_type.value} - ${formData.ap_propertyAddress.value}`;
    const realEstateData = {
        ap_name: name,
        ap_appraisedAmount: formData.ap_appraisedAmount.value,
        ap_assetClass: formData.ap_assetClass.value,
        ap_specialClass: formData.ap_specialClass.value,
        ap_existingPayout: formData.ap_existingPayout.value,
        ap_price: utils.convertToDBCurrency(formData.ap_price.value),
        ap_propertyAddress: formData.ap_propertyAddress.value,
        ap_type: formData.ap_type.value,
        ap_zoning: formData.ap_zoning.value,
    }
    return cleanData(realEstateData);
}

async function businessLendingAssetBuilder(formData) {
    const name = `${formData.ap_type.value} - ${formData.ap_propertyAddress.value}`;
    const realEstateData = {
        ap_name: name,
        ap_loanType: formData.ap_loanType.value,
        ap_price: utils.convertToDBCurrency(formData.ap_price.value),
        ap_type: formData.ap_type.value,
    }
    return cleanData(realEstateData);
}

async function defaultAssetBuilder(formData, newApp, id) {
    const make = (
        ((formData?.ap_truckBrand?.value && formData?.ap_truckBrand?.value !== "Other") ? formData?.ap_truckBrand?.value : '') ||
        ((formData?.ap_trailerBrand?.value && formData?.ap_trailerBrand?.value !== "Other") ? formData?.ap_trailerBrand?.value : '') ||
        formData?.ap_brand?.value || ''
    );

    const name = [
        formData.ap_year.value,
        make,
        formData.ap_model.value,
        formData.ap_type.value
    ];
    const newData = {
        ap_name: name.filter(item => item !== "").join(" "),
        ap_type: formData.ap_type.value,
        ap_brand: formData.ap_brand.value,
        ap_purpose: formData.ap_purpose.value || 'N/A',
        ap_purposeOther: formData.ap_purposeOther.value,
        ap_make: make,
        ap_model: formData.ap_model.value,
        ap_year: formData.ap_year.value,
        ap_vin: formData.ap_vin.value,
        ap_serialNum: formData.ap_serialNum.value,
        ap_vinSerial: formData.ap_vin.value ? formData.ap_vin.value : formData.ap_serialNum.value,
        ap_kms: formData.ap_kms.value,
        ap_price: utils.convertToDBCurrency(formData?.ap_price?.value),
        ap_platingaddress: formData.ap_platingaddress.value,
        ap_yardaddress: formData.ap_yardaddress.value,
        ...(formData?.ap_vendorId?.value) && { ap_vendorId: formData.ap_vendorId.value },
        ...(formData?.ap_truckBrand?.value) && { ap_truckBrand: formData.ap_truckBrand.value },
        ...(formData?.ap_trailerBrand?.value) && { ap_trailerBrand: formData.ap_trailerBrand.value },
        ...(formData?.ap_platingprovince?.value) && { ap_platingprovince: formData.ap_platingprovince.value },
    }

    // if (formData?.ap_vendorId?.value) {
    //     newData.ap_vendorId = formData.ap_vendorId.value;
    // }
    // if (!!formData.ap_truckBrand.value) {
    //     newData.ap_truckBrand = formData.ap_truckBrand.value;
    // }

    // if (!!formData.ap_trailerBrand.value) {
    //     newData.ap_trailerBrand = formData.ap_trailerBrand.value;
    // }

    // if (!!formData.ap_platingprovince.value) {
    //     newData.ap_platingprovince = formData.ap_platingprovince.value;
    // }

    if (!newApp || id) {
        newData.ap_applicationId = id;
        newData.ap_new = true;
    }

    return cleanData(newData);
}

const cleanData = (uncleanData) =>
    Object.fromEntries(
        Object.entries(uncleanData).filter(([_, value]) => value != null && value !== "")
    );

const CreatAssetUtils = {
    verticalSetter,
    creTypeAssociator,
    handleInput,
    handleNewVendor,
    fetchVendorList,
    handleFormVisibility,
    handleCreateAsset,
    assetTypeDropdownContent,
}

export default CreatAssetUtils;