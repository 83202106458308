import { useState } from "react";
import { Tabs } from "antd";

import HomeContent from "../../components/HomeContent";
import ApplicationList from "../../layouts/ApplicationList";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import DraftedApplicationList from "../../layouts/DraftedApplicationList";
import { useUpdateActiveTab } from "../../utils/UpdateActiveTab";
import CreateApplicationModal from "../../layouts/CreateApplicationModal";

export default function Application({ agentId }) {

    const [tab, setTab] = useState("drafted");

    const tabItems = [
        {
            key: 'drafted',
            label: 'Drafted',
            children: <DraftedApplicationList setTab={setTab} />
        },
        {
            key: 'open',
            label: 'Open',
            children: <ApplicationList agentId={agentId} status="Open" tab={tab} />
        },
        {
            key: 'won',
            label: 'Won',
            children: <ApplicationList agentId={agentId} status="Won" tab={tab} />
        },
        {
            key: 'lost',
            label: 'Lost',
            children: <ApplicationList agentId={agentId} status="Lost" tab={tab} />
        }
    ];

    // custom hook to update the active tab based on the query params
    useUpdateActiveTab(tab, setTab, tabItems, "drafted");

    const content = (
        <div className={styles["Container"]}>
            <Tabs
                activeKey={tab}
                items={tabItems}
                onChange={(key) => setTab(key)}
            />
        </div>
    );

    return (
        <>
            <HomeContent
                contentHeader={
                    <div className={styles["ApplicationHeader_Content"]}>
                        <div className="Font_SemiBold">Applications</div>
                        <CreateApplicationModal />
                    </div>
                }
                content={content}
                bgColor="transparent"
            />
        </>
    );
}