export default [
    {
        label: 'Acquisition',
        value: 'Acquisition'
    },
    {
        label: 'Construction',
        value: 'Construction'
    },
    {
        label: 'Refinance',
        value: 'Refinance'
    },
];