import { ConfigProvider, DatePicker, Form, theme } from 'antd';
import RequiredLabelIndicator from '../RequiredLabelIndicator';
import styles from "./index.module.css";

export default function DateComponent({ data, item, layout, autoFill }) {
    const { token: { ap_userPrimaryColor, ap_userHighlightColor, themePrimaryColor, themeDangerColor, themeFontColor, themeDisabledContainer, autoFillDisabled }, } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    activeBorderColor: ap_userPrimaryColor,
                    hoverBorderColor: ap_userPrimaryColor,
                    // colorBgContainer: themePrimaryColor,
                    colorBgContainer: data?.error ? `${themeDangerColor}40` : themePrimaryColor,
                    colorBgContainerDisabled: autoFill ? autoFillDisabled : themeDisabledContainer,
                    colorFillAlter: themePrimaryColor,
                    colorText: themeFontColor,
                    colorTextPlaceholder: themeFontColor,
                    colorTextDisabled: themeFontColor,
                    colorIcon: themeFontColor,
                    colorBorder: ap_userHighlightColor
                }
            }}
        >
            {data.hidden ? null : (
                <div className={(layout == 'horizontal') ? styles["inner-content"] : ''}>
                    <Form.Item
                        key={item}
                        label={<RequiredLabelIndicator label={data?.label} required={data?.required} />}
                    >
                        {layout == 'vertical' ?
                            <>
                                <DatePicker
                                    onChange={data.eventHandler}
                                    value={data.value}
                                    status={!!data.error ? 'error' : ''}
                                    style={{ height: 'fit-content', width: '100%' }}
                                    format='DD-MM-YYYY'
                                    defaultValue={data.value}
                                    picker={data.pickerType}
                                    disabledDate={data.disabledDate}
                                    disabled={data?.disabled}
                                />
                                {!!data?.error && <div className={styles["VerticalWarning"]}>{data?.error}</div>}
                            </>
                            : null}
                    </Form.Item>
                    {layout == 'horizontal' ?
                        <>
                            <DatePicker
                                onChange={data.eventHandler}
                                value={data.value}
                                status={!!data.error ? 'error' : ''}
                                style={{ height: 'fit-content', width: '100%' }}
                                format={data.format ? data.format : 'DD-MM-YYYY'}
                                defaultValue={data.value}
                                picker={data.pickerType}
                                disabledDate={data.disabledDate}
                                disabled={data?.disabled}
                            />
                            {!!data?.error && <div className={styles["HorizontalWarning"]}>{data?.error}</div>}
                        </>
                        : null}
                </div>
            )}
        </ConfigProvider>
    )
}