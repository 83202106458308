// Importing necessary components and styles
import { Tooltip, theme } from "antd";
import LensIcon from '@mui/icons-material/Lens';

import DownloadIcon from "../../assets/images/icons/DownloadIcon";
import PrintIcon from "../../assets/images/icons/PrintIcon";

import styles from "./index.module.css";

export default function DocumentList({ documentsList, opportunityType }) {
    // Handling case when no documents are found
    if (!documentsList || Object.keys(documentsList).length === 0) {
        return <div>No documents found.</div>;
    }

    const { token: { ap_userPrimaryColor } } = theme.useToken();

    /**
     * Sorts an array of documents based on the 'required' property.
     * @param {Object[]} documentsList - The array of documents to be sorted.
     * @returns {Object[]} - Returns a new array of documents sorted by the 'required' property.
     */
    const sortedDocumentsObject = Object.values(documentsList).sort((a, b) => {
        if (a.required === b.required) {
            return 0;
        }
        return a.required ? -1 : 1;
    });

    // Function to download a resource
    const downloadResource = (resource) => {
        if (resource) {
            window.location.href = resource;
        }
    };

    // Function to print a resource
    const printResource = (resource) => {
        if (resource) {
            const printWindow = window.open(resource, '_blank');
            printWindow.onload = () => {
                printWindow.print();
            };
        }
    };

    return (
        <>
            {/* Iterating over sorted documents */}
            {sortedDocumentsObject.map((document, index) => {
                // Generating tooltip title based on document properties
                const tooltipTitle = (
                    <div className={styles["CenterIcon"]}>
                        <LensIcon style={document.required ? { fontSize: 'larger', color: '#FF5555' } : { fontSize: 'larger', color: ap_userPrimaryColor }} />
                        {document.required ? "Required" : "Optional"}
                    </div>
                );

                // Generating document icon with tooltip
                const documentIcon = (
                    <Tooltip title={tooltipTitle}>
                        <LensIcon className={document.required ? styles["RequiredIcon"] : styles["OptionalIcon"]} />
                    </Tooltip>
                );

                // Rendering document details and action icons if the document is not hidden
                if (!document.hidden) {
                    return (
                        <div key={index} className={styles["DocumentContainer"]}>
                            <div>{documentIcon}</div>
                            <div>
                                <div className={styles["Header"]}>{document.name}</div>
                                <div className={styles["SubHeader"]}>{document.additionalInfo}</div>
                            </div>
                            <div className={document.resource ? styles["ActionIcons"] : styles["HideContainer"]}>
                                <div onClick={() => downloadResource(document.resource)} className={styles["CenterIcon"]}>
                                    <DownloadIcon />
                                </div>
                                <div onClick={() => printResource(document.resource)} className={styles["CenterIcon"]}>
                                    <PrintIcon />
                                </div>
                            </div>
                        </div>
                    );
                }
                // Returning null if the document is hidden
                return null;
            })}
        </>
    );
}