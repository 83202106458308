import dayjs from "dayjs";
import ApiService from "../../api";
import NotificationService from "../../services/notification.service";
import validate from "../../utils/Validations";
import utils from "../../utils";

// Client Handler
const handleClient = (value, setLoader, setAppDetailsData) => {
    const agentId = JSON.parse(localStorage?.getItem("user"))?.agent_id;
    const clientId = value;
    setLoader({ loading: true, message: 'Verifying client...' });
    ApiService.fetchClientOpenApplications(agentId, clientId).then((openApps) => {
        if (!openApps) {
            setLoader({ loading: false, message: '' });
            return;
        }

        if (openApps?.length > 0) {
            NotificationService.error('Client already has an open application');
            setAppDetailsData(prevAppDetailsData => ({
                ...prevAppDetailsData,
                client: {
                    ...prevAppDetailsData.client,
                    value: '',
                    error: "This client already has an open application."
                }
            }));
        } else {
            setAppDetailsData(prevAppDetailsData => ({
                ...prevAppDetailsData,
                client: {
                    ...prevAppDetailsData.client,
                    value: clientId,
                    error: validate?.RequiredField(clientId)
                }
            }));
        }
        setLoader({ loading: false, message: '' });
    });
}

// Application Type Handler
const handleApplicationType = (value, setAppDetailsData) => {
    setAppDetailsData(prevAppDetailsData => ({
        ...prevAppDetailsData,
        applicationType: {
            ...prevAppDetailsData.applicationType,
            value: value,
        },
        filedFSYearEnd: {
            ...prevAppDetailsData.filedFSYearEnd,
            required: value === 'Application with Financials',
            error: ''
        }
    }));
}

// CRE Application Type Handler
const handleCREApplicationType = (value, setAppDetailsData) => {
    setAppDetailsData(prevAppDetailsData => ({
        ...prevAppDetailsData,
        creApplicationType: {
            ...prevAppDetailsData.creApplicationType,
            value: value,
        }
    }))
}

// Filed FS Year End Handler
const handleFiledFSYearEnd = (dateString, setAppDetailsData) => {
    setAppDetailsData(prevAppDetailsData => ({
        ...prevAppDetailsData,
        filedFSYearEnd: {
            ...prevAppDetailsData.filedFSYearEnd,
            value: dateString ? dayjs(dateString, "DD-MM-YYYY") : '',
            error: prevAppDetailsData?.applicationType?.value === 'Application with Financials' ? validate.RequiredField(dateString) : ''
        }

    }))
}

// Closing Date Handler
const handleClosingDate = (dateString, setKeyMileStonesData) => {
    setKeyMileStonesData(prevKeyMileStoneData => ({
        ...prevKeyMileStoneData,
        closingDate: {
            ...prevKeyMileStoneData.closingDate,
            value: dateString ? dayjs(dateString, "DD-MM-YYYY") : ''
        }

    }))
}

// Waiver Date Handler
const handleWaiverDate = (dateString, setKeyMileStonesData) => {
    setKeyMileStonesData(prevKeyMileStoneData => ({
        ...prevKeyMileStoneData,
        waiverDate: {
            ...prevKeyMileStoneData.waiverDate,
            value: dateString ? dayjs(dateString, "DD-MM-YYYY") : ''
        }

    }))
}

// File Uploader
const handleFileUpload = (id, file, setFiles) => {
    const reader = new FileReader();

    reader.onload = function (event) {
        const base64 = event.target.result;
        const fileData = {
            data: base64,
            fileName: utils.sanitizeFileName(file?.file?.name),
            folderName: `Applications/${id}/`,
            containerName: JSON.parse(localStorage?.getItem("user"))?.agent_id,
        }

        ApiService.uploadDocs(fileData).then((response) => {
            if (response?.error)
                return;

            setFiles(prevFiles => [
                ...prevFiles,
                {
                    name: utils.sanitizeFileName(file?.file?.name),
                    url: response.data.url,
                    sentToSharePoint: false,
                }
            ])
        });
    }

    reader.onerror = function (event) {
        console.error('Error reading file: ', event)
    }

    reader.readAsDataURL(file?.file);
}

const updateApplication = (setLoader, setRefresh, applicationData, id, assets, setRefreshAssets, files, coApps, pgData, setValidatePg, openUpdateModal, setOpenUpdateModal, reason = null, setReason, setRefreshPgs, keyMileStonesData = {}) => {
    setValidatePg(prevValidatePg => prevValidatePg + 1);
    const pgError = pgData.some(data => {
        const hasContactIdError = validate.RequiredField(data.ap_contactId) !== "";
        const hasBirthDateError = validate.RequiredField(data.ap_birthDate) !== "";
        const hasDrivingExpError = data.ap_drivingExperienceYears ? validate.ValidateDrivingExperience(data.ap_drivingExperienceYears).error !== "" : '';
        const hasSinError = validate.ValidateSIN(data.ap_sin).error !== "";
        return hasContactIdError || hasBirthDateError || hasDrivingExpError || hasSinError;
    });

    const coAppError = coApps.some(data => {
        return validate.RequiredField(data.ap_clientId) !== "";
    })

    if (pgError || coAppError) {
        const elementToScrollTo = document.getElementById('PgCoAppContainer');
        if (elementToScrollTo) {
            elementToScrollTo.style.scrollMarginTop = '20px';
            elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

    } else {
        const { askReason, sendNotification } = utils.AssetManager(assets);

        if (askReason && !openUpdateModal) {
            // Open Modal to ask reason
            setOpenUpdateModal(true);
        } else {
            setReason(prevReason => ({
                ...prevReason,
                reason: {
                    ...prevReason?.reason,
                    error: validate.RequiredField(prevReason?.reason?.value)
                }
            }))

            if ((askReason && reason) || !askReason)
                handleUpdateApplication(setLoader, setRefresh, applicationData, id, assets, setRefreshAssets, pgData, coApps, files, reason, setOpenUpdateModal, setRefreshPgs, keyMileStonesData);
        }
    }
}

const handleUpdateApplication = (setLoader, setRefresh, applicationData, id, assets, setRefreshAssets, pgData, coApps, files, reason, setOpenUpdateModal, setRefreshPgs, keyMileStonesData) => {
    setLoader({ loading: true, message: 'Updating Application...' });

    const newAssets = assets.filter(item => !item.hasOwnProperty('_id'));
    newAssets.forEach(asset => {
        asset.ap_applicationId = id;
        asset.ap_edited = false;
        asset.ap_deleted = false;
        ApiService.createAsset(asset).then(assetResponse => {
            if (!assetResponse)
                console.error("Error creating asset with data: ", asset)
            // else setRefreshAssets(prevRefreshAssets => prevRefreshAssets + 1);
        });
    });

    const editedOrDeletedAssets = assets.filter(item => item.hasOwnProperty('_id') && (item.ap_edited || item.ap_deleted));

    editedOrDeletedAssets.forEach(asset => {
        if (asset?.ap_deleted)
            asset.ap_edited = false;
        asset.ap_price = String(asset.ap_price);
        ApiService.updateAsset(asset._id, asset).then(assetResponse => {
            if (!assetResponse)
                console.error("Error updating asset with data: ", asset);
        });
    });

    const newPGs = pgData.filter(item => !item.hasOwnProperty('_id'));
    newPGs.forEach(pg => {
        pg.ap_applicationId = id;
        pg.ap_new = true;
        pg.ap_edited = false;
        pg.ap_deleted = false;

        ApiService.createPG(pg).then(pgResponse => {
            if (!pgResponse)
                console.error("Error creating PG with data: ", pg);
        });
    });

    const updatedPGs = pgData.filter(item => item.hasOwnProperty('_id') && (item?.updated || item?.ap_deleted));
    const numUpdatedPGs = updatedPGs?.length;
    let updatedPGsCounter = 0;
    updatedPGs.forEach(pg => {
        if (!pg?._id) return;
        if (pg?.ap_sin?.startsWith("xx")) delete pg.ap_sin;

        pg.ap_new = false;
        pg.ap_deleted = pg?.ap_deleted === true ? true : false;
        pg.ap_edited = pg?.updated === true ? true : false;

        let updateOrDeletePromise;
        if (pg.ap_deleted) updateOrDeletePromise = ApiService.deletePG(pg._id);
        else updateOrDeletePromise = ApiService.updatePG(pg._id, pg);

        updateOrDeletePromise.then(pgResponse => {
            updatedPGsCounter++;
            if ((updatedPGsCounter === numUpdatedPGs) && setRefreshPgs)
                setRefreshPgs(prevRefreshPgs => prevRefreshPgs + 1);
            if (!pgResponse)
                console.error("Error updating or deleting PG with data: ", pg);
        });
    })

    const normalizeCoApplicant = (coApp) => coApp ? (coApp._id || coApp) : null;

    const activeAssets = assets.filter(asset => asset?.ap_assetStatus !== 'Inactive' || !asset?.ap_deleted || asset?.ap_assetStatus !== 'Cancelled');
    const oldApplicationData = {
        ap_assetDescription: applicationData?.ap_assetDescription,
        ap_requestedAmount: String(applicationData?.ap_requestedAmount),
        ap_documents: applicationData?.ap_documents,
        ap_reason: applicationData?.ap_reason,
        // the above fields has been modifiled in order to avoid changes b/w old and new application data, without making any actual changes in data
        ap_corporateCoApplicant1: normalizeCoApplicant(applicationData.ap_corporateCoApplicant1),
        ap_corporateCoApplicant2: normalizeCoApplicant(applicationData.ap_corporateCoApplicant2),
        ap_corporateCoApplicant3: normalizeCoApplicant(applicationData.ap_corporateCoApplicant3),
        ap_corpCoApplicant: applicationData?.ap_corpCoApplicant || 'None',
        ap_estimatedClosingDate: applicationData?.ap_estimatedClosingDate ? new Date(applicationData?.ap_estimatedClosingDate) : null,
        ap_estimatedWaiverDate: applicationData?.ap_estimatedWaiverDate ? new Date(applicationData?.ap_estimatedWaiverDate) : null,
    };

    const updateApplicationData = {
        ap_assetDescription: utils.GenerateAssetDescription(activeAssets),
        ap_requestedAmount: utils.convertToDBCurrency(activeAssets.reduce((total, asset) => total + Number(asset?.ap_price || 0), 0)),
        ap_documents: {
            ...applicationData?.ap_documents,
            files: files,
        },
        ap_reason: reason,
        ap_corporateCoApplicant1: null,
        ap_corporateCoApplicant2: null,
        ap_corporateCoApplicant3: null,
        ap_estimatedClosingDate: keyMileStonesData?.closingDate?.value?.$d || null,
        ap_estimatedWaiverDate: keyMileStonesData?.waiverDate?.value?.$d || null,
    }

    // Co-Applicants
    const corpMapper = { 0: 'None', 1: 'One', 2: 'Two', 3: 'Three' }
    let corpCounter = 0;
    coApps.forEach(coApp => {
        const currentCoAppId = coApp?.ap_clientId?._id || coApp?.ap_clientId
        if (currentCoAppId) {
            corpCounter++;
            updateApplicationData[`ap_corporateCoApplicant${corpCounter}`] = currentCoAppId;
        }
    });
    updateApplicationData["ap_corpCoApplicant"] = corpMapper[corpCounter];

    const isApplicationUpdated = newAssets?.length > 0 || editedOrDeletedAssets?.length > 0 || newPGs?.length > 0 || updatedPGs?.length > 0 || JSON.stringify(oldApplicationData, Object.keys(oldApplicationData).sort()) !== JSON.stringify(updateApplicationData, Object.keys(updateApplicationData).sort());

    if (isApplicationUpdated) {
        ApiService.updateApplication(id, updateApplicationData).then(response => {
            if (response?.success) {
                setRefresh(prevRefresh => prevRefresh + 1);
                setRefreshPgs(prevRefreshPgs => prevRefreshPgs + 1);
                setRefreshAssets(prevRefreshAssets => prevRefreshAssets + 1);
                setOpenUpdateModal(false);
                NotificationService.success("Application Updated Successfully");
            }
            setLoader({ loading: false, message: '' });
            setRefresh(prevRefresh => prevRefresh + 1);
        });
    } else {
        NotificationService.success("Nothing to update!");
        setLoader({ loading: false, message: '' });
        setRefresh(prevRefresh => prevRefresh + 1);
        setOpenUpdateModal(false);
    }
}

const handleSendApplication = (setValidatePg, setLoader, appDetailsData, setAppDetailsData, assets, setShowAssetError, files, setShowDocumentsError, pgData, coApps, setOpenSendModal) => {
    setValidatePg(prevValidatePg => prevValidatePg + 1);
    setLoader({ loading: true, message: 'Validating Application Details...' });
    const isAppOnly = appDetailsData?.applicationType?.value === 'Application Only';

    const tempAppDetailsData = {
        ...appDetailsData,
        client: {
            ...appDetailsData?.client,
            error: validate.RequiredField(appDetailsData?.client?.value)
        },
        applicationType: {
            ...appDetailsData?.applicationType,
            error: validate.RequiredField(appDetailsData?.applicationType?.value)
        },
        filedFSYearEnd: {
            ...appDetailsData?.filedFSYearEnd,
            error: isAppOnly ? '' : validate.RequiredField(appDetailsData?.filedFSYearEnd?.value)
        }
    };
    setAppDetailsData(tempAppDetailsData);

    const hasAppDetailsError = Object.values(tempAppDetailsData).some(item => item?.error);

    setLoader({ loading: true, message: 'Validating Assets...' });
    const hasAssetError = assets?.length === 0;
    setShowAssetError(hasAssetError);

    setLoader({ loading: true, message: 'Validating Documents...' });
    const hasDocuments = files?.length > 0;
    setShowDocumentsError(!hasDocuments);

    setLoader({ loading: false, message: '' });

    const pgError = pgData.some(data => {
        const hasContactIdError = validate.RequiredField(data.ap_contactId) !== "";
        const hasBirthDateError = validate.RequiredField((data.ap_birthDate)?.toISOString()) !== "";
        const hasDrivingExpError = (data.ap_drivingExperienceYears) ? validate.ValidateDrivingExperience(data.ap_drivingExperienceYears).error !== "" : "";
        const hasSinError = validate.ValidateSIN(data.ap_sin).error !== "";

        return hasContactIdError || hasBirthDateError || hasDrivingExpError || hasSinError;
    });

    const coAppError = coApps.some(data => {
        return validate.RequiredField(data.ap_clientId) !== "";
    })

    if (!hasAppDetailsError && !hasAssetError && hasDocuments && !pgError && !coAppError) {
        setOpenSendModal(true);
    } else {
        setOpenSendModal(false);
        const scrollContainer = hasAppDetailsError ? "AppDetailsContainer" : (pgError || coAppError) ? "PgCoAppContainer" : hasAssetError ? "AssetContainer" : !hasDocuments ? "NewDocumentsContainer" : "";

        const elementToScrollTo = document.getElementById(scrollContainer);
        if (elementToScrollTo) {
            elementToScrollTo.style.scrollMarginTop = '20px';
            elementToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}

const handleSubmit = (id, setLoader, appDetailsData, assets, pgData, coApps, files, fileComments, documentsList, setRefresh, setSent, setHeaderName, setOpenSendModal) => {
    setLoader({ loading: true, message: 'Submitting Application...' });
    const data = {
        ap_agentId: JSON.parse(localStorage?.getItem("user"))?.agent_id,
        ap_clientId: appDetailsData?.client?.value,
        ap_assetDescription: utils.GenerateAssetDescription(assets),
        ap_applicationType: appDetailsData?.applicationType?.value,
        ap_filedFSYearEnd: appDetailsData?.filedFSYearEnd?.value?.$d,
        ap_requestedAmount: utils.convertToDBCurrency(assets.reduce((total, asset) => total + Number(asset?.ap_price || 0), 0)),
        ap_documents: {
            files: files,
            uploadedDocuments: Object.values(documentsList).filter(doc => doc?.checked).map(doc => doc?.name)
        },
        ap_comments: fileComments
    }

    // Add Corporate Co-Applicants to the application
    const corpMapper = { 0: 'None', 1: 'One', 2: 'Two', 3: 'Three' }
    let corpCounter = 0;
    coApps.forEach(coApp => {
        if (coApp?.ap_clientId) {
            corpCounter++;
            data[`ap_corporateCoApplicant${corpCounter}`] = coApp?.ap_clientId;
        }
    });
    data.ap_corpCoApplicant = corpMapper[corpCounter];

    ApiService.createApplication(data).then(applicationResponse => {
        if (!applicationResponse) return;
        const applicationId = applicationResponse?.success?.applicationId;
        if (applicationId) {
            // Assign Assets to the application
            assets.forEach(asset => {
                asset.ap_applicationId = applicationId;
                asset.ap_vendorId = asset?.ap_vendorId?._id || asset?.ap_vendorId;
                ApiService.createAsset(asset).then(assetResponse => {
                    if (!assetResponse)
                        console.error("Error creating asset with data: ", asset)
                });
            });

            // PG creation API
            pgData.forEach(pg => {
                pg.ap_applicationId = applicationId;

                ApiService.createPG(pg).then(pgResponse => {
                    if (!pgResponse)
                        console.error("Error creating PG with data: ", pg);
                });
            });

            // Move the documents from Draft folder to application folder
            const blobData = {
                containerName: JSON.parse(localStorage?.user)?.agent_id,
                sourceFolderName: `Applications/Drafts/${id}/`,
                destinationFolderName: `Applications/${applicationId}/`,
            }

            ApiService.moveDocuments(blobData).then(documentsResponse => {
                if (!documentsResponse) return;
                if (documentsResponse?.code === 200) {
                    const updatedFileData = {
                        ap_documents: {
                            ...(data?.ap_documents || {}),
                            files: files.map(file => ({
                                ...file,
                                url: utils.UpdateFileUrl(file?.url, `Drafts/${id}`, applicationId)
                            }))
                        }
                    };

                    ApiService.updateApplication(applicationId, updatedFileData).then(response => {
                        if (!response) return;
                        if (response?.success)
                            setRefresh(prevRefresh => prevRefresh + 1);
                    });
                }
            });
            setSent(true);
            setHeaderName(null);
            setOpenSendModal(false);
            setLoader({ loading: false, message: '' });
        } else setLoader({ loading: false, message: '' });
    });
}

export default {
    handleClient,
    handleApplicationType,
    handleFiledFSYearEnd,
    handleFileUpload,
    updateApplication,
    handleUpdateApplication,
    handleSendApplication,
    handleSubmit,
    handleWaiverDate,
    handleClosingDate,
    handleCREApplicationType
}