import { Button, Modal } from "antd";
import styles from "./index.module.css";
import { useState } from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";

export default function CreateApplicationModal() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const verticles = [
        {
            key: 'equipment',
            label: 'Equipment Financing',
            icon: 'https://lanternpublicstaticfiles.blob.core.windows.net/main/Application Types/OppType_EQ.png',
            onClick: () => navigate('/applications/new?ap_type=Equipment')
        },
        {
            key: 'commercialRealEstate',
            label: 'Commercial Real Estate',
            icon: 'https://lanternpublicstaticfiles.blob.core.windows.net/main/Application Types/OppType_CRE.png',
            onClick: () => navigate('/applications/new?ap_type=Commercial Real Estate')
        },
        {
            key: 'businessLending',
            label: 'Business Lending',
            icon: 'https://lanternpublicstaticfiles.blob.core.windows.net/main/Application Types/OppType_BL.png',
            onClick: () => navigate('/applications/new?ap_type=Business Lending')
        }
    ]

    return (
        <>
            <Modal
                open={open}
                closable={false}
                footer={null}
                centered
                width={780}
                getContainer=".AppTheme"
            >
                <div className={styles["ApplicationTypeModal_Header"]}>
                    <div>
                        <div>Choose Application Type</div>
                        <div onClick={() => setOpen(false)}><CloseOutlined /></div>
                    </div>
                    <div>Select the type of application you'd like to create.</div>
                </div>

                <div className={styles["ApplicationTypeModal_Content"]}>
                    {verticles.map((verticle, index) => (
                        <div
                            key={index}
                            onClick={verticle.onClick}
                        >
                            <img src={verticle.icon} alt={verticle.label} style={{width: '120px', height: '120px'}}/>
                            <div>{verticle.label}</div>
                        </div>
                    ))}
                </div>
            </Modal>

            <Button
                type="primary"
                className={styles["ApplicationHeader_Button"]}
                // onClick={() => navigate("/applications/new")}
                onClick={() => setOpen(true)}
            >
                <PlusOutlined /> Application
            </Button>
        </>
    )
}