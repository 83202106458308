import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

import Error404 from "../../layouts/Error404";
import IndividualResources from "../../layouts/IndividualResources";
import HomeContent from "../../components/HomeContent";
import constants from "../../constants";

import ApiService from "../../api";

import styles from "./index.module.css";
import "../../assets/styles/fonts.css";
import { Spin } from "antd";

export default function IndividualResource() {

    const { id, type } = useParams();
    const navigate = useNavigate();

    const [headerName, setHeaderName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [resourceData, setResourceData] = useState([]);

    const typeMapper = {
        'branded': {
            'attributeName': 'ap_branded',
            'headerName': 'Branded'

        },
        'white-label': {
            'attributeName': 'ap_whiteLabel',
            'headerName': 'White Label'
        }
    }


    useEffect(() => {


        const typeValid = constants?.RESOURCE_TYPE?.includes(type?.toLowerCase());

        if (!id) {
            // if (!id || !typeValid) {
            setShowError(true);
            return;
        }

        setLoading(true);
        ApiService.fetchIndividualResource(id).then(data => {
            console.log('Ind_Res: ', data)
            setShowError(!data);
            setResourceData(prevData => {
                if (!data)
                    return {};

                if (typeValid)
                    return data[typeMapper[type?.toLowerCase()]?.attributeName];

                if (data?.ap_list?.length)
                    return data?.ap_list;

                return {};
            });

            setHeaderName(prevName => {
                if (!data)
                    return '';

                if (typeValid)
                    return `${data?.ap_name} - ${typeMapper[type?.toLowerCase()]?.headerName}`;

                if (data?.ap_list?.length)
                    return data?.ap_name;

                return ''
            });
            setLoading(false);
        });

    }, [id, type]);

    return (
        <>
            <HomeContent
                contentHeader={<>
                    {showError ?
                        <></> :
                        <div className={`${styles['Header']} Font_SemiBold`}>
                            <LeftOutlined onClick={() => navigate('/resources')} />
                            Resources | <span className={styles["Header_Title"]}>{headerName}</span>
                        </div>
                    }
                </>}
                content={<>
                    {showError ?
                        <div style={{ maxHeight: '73vh', overflowY: 'hidden' }}>
                            <Error404 />
                        </div> :
                        <Spin spinning={loading}>
                            <IndividualResources data={resourceData} />
                        </Spin>
                    }
                </>}
                bgColor="transparent"
            />
        </>
    );
}
