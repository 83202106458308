import { createRef, useEffect, useRef, useState } from "react";
import { Button, Drawer, Spin, theme } from "antd";

import CreateAssetConstants from "./constants";
import CreateAssetUtils from "./utils";
import { PlusOutlined } from "@ant-design/icons";
import InputLayout from "../InputLayout";

import styles from "./index.module.css";
import { useParams, useLocation } from "react-router-dom";

export default function CreateNewAsset({
    newApp,
    disabled,
    assets,
    setAssets,
    setUnsaved,
    opportunityType
}) {
    // Get the ID of the Application (if exists)
    const { id } = useParams();
    const location = useLocation();
    const isDraft = location?.pathname?.includes("draft");

    // Create an object of refs for each field by name
    const fieldRefs = useRef({});

    const { token: { userPrimaryColor }, } = theme.useToken();

    // Define State Variables
    const [fieldManager, setFieldManager] = useState({
        open: false,
        invokeFieldValidaton: false,
        isCreOpp: false,
        isBlOpp: false,
        loading: false,
        vendors: [],
        refreshVendors: 0,
    });

    // Will hold the data for the asset being created
    const [data, setData] = useState({});

    // Synchronize Field Manager with Agent Vertical
    useEffect(() => {
        CreateAssetUtils.verticalSetter(opportunityType, setFieldManager);
    }, [opportunityType]);

    // Synchronize Asset Type for Real Estate Agent Vertical
    useEffect(() => {
        CreateAssetUtils.creTypeAssociator(fieldManager.isCreOpp, fieldManager.isBlOpp, assets, setData);
    }, [fieldManager.isCreOpp, fieldManager.isBlOpp, fieldManager.open]);

    // Fetch Existing Vendors
    useEffect(() => {
        CreateAssetUtils.fetchVendorList(setFieldManager)
    }, [fieldManager.open, fieldManager.refreshVendors]);

    const formFields = CreateAssetConstants.AssetFormFields(data, setData, fieldManager, setFieldManager);
    return (
        <>
            <Button
                type="primary"
                disabled={disabled}
                className={styles["assetButton"]}
                icon={<PlusOutlined />}
                onClick={() => CreateAssetUtils.handleFormVisibility(setFieldManager, setData)}
            >
                Asset
            </Button>

            <Drawer
                title="New Asset"
                open={fieldManager.open}
                onClose={() => CreateAssetUtils.handleFormVisibility(setFieldManager, setData)}
                getContainer=".AppTheme"
            >
                <Spin spinning={fieldManager.loading} tip="Loading..." size="large">

                    {Object.keys(formFields).map((field, index) => {
                        const fieldData = formFields[field];

                        if (!fieldRefs.current[field])
                            fieldRefs.current[field] = createRef();

                        return (
                            <div key={index} ref={fieldRefs.current[field]}>
                                <InputLayout
                                    data={{ [field]: fieldData }}
                                    layout="vertical"
                                />
                            </div>
                        )
                    })}

                    <Button
                        type="primary"
                        style={{ backgroundColor: userPrimaryColor, display: 'block', width: 'fit-content', }}
                        onClick={() => CreateAssetUtils.handleCreateAsset(data, setData, fieldManager, setFieldManager, fieldRefs, newApp, id, setAssets, setUnsaved)}
                    >
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <PlusOutlined />
                            <div>Create Asset</div>
                        </div>
                    </Button>
                </Spin>
            </Drawer>
        </>
    )
}