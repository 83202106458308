export default [
    {
        label: 'Operating Line',
        value: 'Operating Line',
    },
    {
        label: 'Trade Facility',
        value: 'Trade Facility',
    },
    {
        label: 'Cash Flow Loan',
        value: 'Cash Flow Loan',
    },
    {
        label: 'Factoring',
        value: 'Factoring',
    },
    {
        label: 'Tech Financing',
        value: 'Tech Financing',
    },
    {
        label: 'CSBFL/Startup Loan',
        value: 'CSBFL/Startup Loan',
    },
    {
        label: 'Working Capital',
        value: 'Working Capital',
    },
]