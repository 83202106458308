export default [
    {
        label: 'Industrial',
        value: 'Industrial',
    },
    {
        label: 'Construction',
        value: 'Construction',
    },
    {
        label: 'Land',
        value: 'Land',
    },
    {
        label: 'Hotel & Resort',
        value: 'Hotel & Resort',
    },
    {
        label: 'Multi Residential & Condominium',
        value: 'Multi Residential & Condominium',
    },
    {
        label: 'Retail',
        value: 'Retail',
    },
    {
        label: 'Office',
        value: 'Office',
    },
    {
        label: 'Special Use',
        value: 'Special Use',
    },
];