import dayjs from "dayjs";
import DataConstants from "../../constants";

function determineOppType(opportunityType = 'Equipment') {
    const isCreOpp = opportunityType === 'Commercial Real Estate';
    const isBlOpp = opportunityType === 'Business Lending';
    const isEquipmentOpp = opportunityType === 'Equipment';
    return { isCreOpp, isBlOpp, isEquipmentOpp };
}

function ApplicationDetails(id, applicationData) {

    const isNewApp = !id;
    const isAppOpen = isNewApp || applicationData?.ap_stateCode === 'Open';

    const { ap_opportunityType } = applicationData;
    const isCreOpp = ap_opportunityType === 'Commercial Real Estate';
    const isBlOpp = ap_opportunityType === 'Business Lending';

    const appDetailsFields = {
        client: {
            value: isNewApp ? '' : (applicationData?.ap_clientId?.ap_businessName || ''),
            error: (!isNewApp && !applicationData?.ap_clientId) ? 'Required' : '',
            hidden: false,
            required: true,
            disabled: !isNewApp || !isAppOpen,
            inputType: 'themed-dropdown-search',
            label: 'Client',
            dropdownContent: [],
            placeholder: 'Search',
            size: 'large',
            enableLock: isNewApp,
        },
        creApplicationType: {
            value: (isCreOpp) ? (applicationData?.ap_creApplicationType || DataConstants.CRE_APPLICATION_TYPE[0]?.value) : '',
            error: '',
            hidden: !isCreOpp,
            required: isCreOpp,
            disabled: !isNewApp || !isAppOpen,
            inputType: 'themed-dropdown-search',
            label: 'Application Type',
            dropdownContent: DataConstants.CRE_APPLICATION_TYPE,
            size: 'large',
            enableLock: isNewApp,
        },
        applicationType: {
            value: (!isNewApp && applicationData?.ap_applicationType) ? applicationData?.ap_applicationType : 'Application with Financials',
            error: '',
            hidden: isCreOpp || isBlOpp,
            required: !(isCreOpp || isBlOpp),
            disabled: !isNewApp || !isAppOpen,
            inputType: 'themed-dropdown-search',
            label: 'Application Type',
            dropdownContent: DataConstants.APPLICATION_TYPE,
            size: 'large',
            enableLock: isNewApp,
        },
        filedFSYearEnd: {
            value: !isNewApp && applicationData?.ap_filedFSYearEnd ? dayjs(applicationData?.ap_filedFSYearEnd) : '',
            error: (!applicationData?.ap_filedFSYearEnd && applicationData?.ap_applicationType === 'Application with Financials') ? 'Required' : '',
            hidden: isNewApp ? false : (applicationData?.ap_applicationType !== 'Application with Financials'),
            required: isNewApp ? true : (applicationData?.ap_applicationType === 'Application with Financials'),
            disabled: !isNewApp || !isAppOpen,
            inputType: 'themed-date',
            label: 'Filed FS Year End',
            size: 'large',
            enableLock: isNewApp,
        },
    }

    return appDetailsFields;
}

function KeyMilestoneDates(id, applicationData) {

    const { ap_opportunityType, ap_estimatedClosingDate, ap_estimatedWaiverDate } = applicationData;
    const isNewApp = !id;
    const isAppOpen = isNewApp || applicationData?.ap_stateCode === 'Open';

    const { isCreOpp, isBlOpp, isEquipmentOpp } = determineOppType(ap_opportunityType);

    return {
        closingDate: {
            value: (ap_estimatedClosingDate) ? dayjs(ap_estimatedClosingDate) : '',
            error: '',
            hidden: isBlOpp || isEquipmentOpp,
            required: false,
            disabled: !isAppOpen,
            inputType: 'themed-date',
            label: 'Estimated Closing Date',
            size: 'large',
            enableLock: isAppOpen,
        },
        waiverDate: {
            value: (ap_estimatedWaiverDate) ? dayjs(ap_estimatedWaiverDate) : '',
            error: '',
            hidden: isBlOpp || isEquipmentOpp,
            required: false,
            disabled: !isAppOpen,
            // disabled: !isNewApp || !isAppOpen,
            inputType: 'themed-date',
            label: 'Estimated Waiver Date',
            size: 'large',
            enableLock: isAppOpen,
        }
    }

}

function Documents(clientType, assets, pgs, opportunityType) {

    const isEquipment = opportunityType === 'Equipment';
    const isBlOpp = opportunityType === 'Business Lending';
    const isCreOpp = opportunityType === 'Commercial Real Estate';

    if (isCreOpp)
        return creDocuments();
    else if (isBlOpp)
        return blDocuments();
    else return equipmentDocuments(clientType, assets, pgs);
}


function equipmentDocuments(clientType, assets, pgs) {
    const isCommercial = (clientType === "Commercial");

    const isPrivateSale = assets?.some(asset => asset?.ap_vendorId?.ap_privateSale === true);

    const pgAvailable = pgs?.length > 0;

    const tempDoc = {
        articlesOfIncorporation: {
            name: 'Articles of Incorporation',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        filledAndSignedApplication: {
            name: 'Filled and Signed Application by All Shareholders(signatures matching those on Drivers Licence)',
            additionalInfo: '*electronic signatures are acceptable',
            required: pgAvailable,
            hidden: false,
            resource: `${process.env.REACT_APP_RESOURCE_URL}/Equipment%20Finance%20Checklist/Branded/Credit%20Application.pdf`,
            checked: false,
        },
        identification: {
            name: '2 Pieces of Identification',
            additionalInfo: '(i.e. Drivers Licence, Passport, Permanent Resident Card)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        financialStatements: {
            name: 'Schedule 100 and 125 (Most Recent)',
            additionalInfo: 'or T2 or accountant prepared financial statements or Bank Statements (3 Months Most Recent)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        voidCheque: {
            name: 'Void Cheque',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        billOfSale: {
            name: 'Bill of Sale',
            additionalInfo: '(for equipment client is looking to purchase)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        operationsSummary: {
            name: 'Operations Summary',
            additionalInfo: '',
            required: isCommercial,
            hidden: false,
            resource: `${process.env.REACT_APP_RESOURCE_URL}/Equipment%20Finance%20Checklist/Branded/Operations%20summary.pdf`,
            checked: false,
        },
        jobLetter: {
            name: 'Job Letter',
            additionalInfo: '',
            required: !isCommercial,
            hidden: false,
            resource: null,
            checked: false,
        },
        assetPictures: {
            name: 'Asset Pictures',
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        ownership: {
            name: 'Ownership',
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        sellerDriversLicence: {
            name: "Seller's Drivers Licence",
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        sellerVoidCheque: {
            name: "Seller's Void Cheque",
            additionalInfo: '',
            required: isPrivateSale,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        payout: {
            name: 'Payout',
            additionalInfo: '(if applicable)',
            required: false,
            hidden: !isPrivateSale,
            resource: null,
            checked: false,
        },
        otherDocuments: {
            name: 'Other Documents',
            additionalInfo: '*Please specify in comments',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
    }
    return tempDoc;
}

function creDocuments() {
    return {
        articlesOfIncorporation: {
            name: 'Articles of Incorporation',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        identification: {
            name: '2 Pieces of Identification',
            additionalInfo: '(i.e. Drivers Licence, Passport, Permanent Resident Card)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        salesPurchase: {
            name: 'Agreement of Purchase and Sale (APS)',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        appraisal: {
            name: 'Appraisal',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        financialStatements: {
            name: 'Schedule 100 and 125 (Most Recent)',
            additionalInfo: 'or T2 or accountant prepared financial statements or Bank Statements (3 Months Most Recent)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        pnw: {
            name: 'Personal Net Worth Statement',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        leaseAgreement: {
            name: 'Lease Agreement',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        propertyStatements: {
            name: 'Property Statements',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        mortgageStatements: {
            name: 'Mortgage Statements',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        rentRoll: {
            name: 'Rent Roll',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        otherDocuments: {
            name: 'Other Documents',
            additionalInfo: '*Please specify in comments',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
    };
}

function blDocuments() {
    return {
        articlesOfIncorporation: {
            name: 'Articles of Incorporation',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        financialStatements: {
            name: '3 Year Financial Statements (Most Recent)',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        t2: {
            name: '3 Year T2 (Most Recent)',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        identification: {
            name: '2 Pieces of Identification',
            additionalInfo: '(i.e. Drivers Licence, Passport, Permanent Resident Card)',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        pnw: {
            name: 'Personal Net Worth Statement',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        bankStatements: {
            name: '6 Month Bank Statements (Most Recent)',
            additionalInfo: '',
            required: true,
            hidden: false,
            resource: null,
            checked: false,
        },
        arApListing: {
            name: 'AR / AP Listing',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        },
        ytdInterims: {
            name: 'YTD Interims',
            additionalInfo: '',
            required: false,
            hidden: false,
            resource: null,
            checked: false,
        }
    }
}

function UpdateReason() {
    const updateReasonFields = {
        reason: {
            value: '',
            error: '',
            hidden: false,
            required: false,
            disabled: false,
            inputType: 'textarea',
            label: '',
            size: 'large',
            rowCount: 4,
        },
    }
    return updateReasonFields;
}

const constants = {
    ApplicationDetails,
    KeyMilestoneDates,
    Documents,
    UpdateReason,
}

export default constants;